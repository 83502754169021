import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { LayoutComponentModule } from '../layout-component/layout-component.module';
import { TemplateDetailsComponent } from './template-details.component';

@NgModule({
  declarations: [TemplateDetailsComponent],
  imports: [TranslateModule, CommonModule, FontAwesomeModule, AccordionModule, LayoutComponentModule],
  exports: [TemplateDetailsComponent],
})
export class TemplateDetailsModule {}
