import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';

import { UserDetailsComponent } from './user-details.component';
import { LayoutComponentModule } from '../layout-component/layout-component.module';

@NgModule({
  declarations: [UserDetailsComponent],
  imports: [TranslateModule, CommonModule, FontAwesomeModule, AccordionModule, LayoutComponentModule],
  exports: [UserDetailsComponent],
})
export class UserDetailsModule {}
