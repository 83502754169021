<div class="modal-header border-0 pt-4 px-4 mx-2 pb-1">
  <h1 class="modal-title h4 w-100 text-center" id="modal-basic-title" translate>{{ title }}</h1>
  <button type="button" class="close" (click)="activeModal.dismiss()">
    <fa-icon [icon]="['fal', 'times']" [fixedWidth]="true"></fa-icon>
  </button>
</div>
<div class="modal-body border-0 px-4 mx-2">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <p class="mb-3"><span translate>users.merge.user_to_keep</span>:</p>
        <p><strong>{{userToKeep?.firstname}} {{userToKeep?.lastname}}</strong></p>
        <p>{{userToKeep?.email}}</p>
        <p>{{userToKeep?.phone}}</p>
        <p class="text-muted">{{getRolesAsString(userToKeep.roles_details)}}</p>
      </div>
      <div class="col-6">
        <p class="mb-3"><span translate>users.merge.user_to_merge</span>:</p>
        <p><strong>{{userToMerge?.firstname}} {{userToMerge?.lastname}}</strong></p>
        <p>{{userToMerge?.email}}</p>
        <p>{{userToMerge?.phone}}</p>
        <p class="text-muted">{{getRolesAsString(userToMerge.roles_details)}}</p>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-center border-0 pb-4">
  <button type="button" class="btn btn-outline-secondary me-3" (click)="activeModal.dismiss()" translate>common.cancel</button>
  <button type="button" class="btn ms-1 btn-success" (click)="activeModal.close()">
    <span translate>users.merge.merge_users</span>
  </button>
</div>
