import { Component, Injectable, Input, NgModule, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrService } from 'ngx-toastr';
import { Organisation } from '../../models/organisation.model';
import { switchMap, map } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CurrentUserResponse, UserService } from '../../services/user.service';
import { User } from '../../models/user.model';
import { Helpers } from '../../services/helpers';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  templateUrl: './change-organisation-dialog.component.html',
  styleUrls: ['./change-organisation-dialog.component.scss'],
})
export class ChangeOrganisationDialogContentComponent implements OnInit {
  @Input() title = '';
  @Input() text = '';
  @Input() additionalText = '';
  @Input() data = '';
  @Input() item = '';
  @Input() buttonText = '';
  @Input() buttonStyle = '';
  @Input() cancelText = 'common.cancel';

  // Participant submitting details
  @Input() organisations: Organisation[];
  
  userInformation;
  account: User;
  organisationsForUser = [];
  regionCode = 'FI';
  validationsCompleted = false;

  organisationForm = this.fb.group({
    organisation_id: ['', Validators.required],
  });


  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal, 
    private translate: TranslateService, 
    private toastr: ToastrService,
    public auth: AuthService,
    public authGuardService: AuthGuardService,
    private organisationService: OrganisationService,
    private userService: UserService,
    private helpers: Helpers,

  ) {}

  async ngOnInit() {
    const sessionData = this.authGuardService.getSessionData();

    if (sessionData) {
      this.userInformation = {
        organisationId: sessionData.user.logged_in_organisation.id,
        userId: sessionData.user.id,
      };
      
      await this.getOrganisations();

      this.userService.getUser(this.userInformation.userId, { params: { with: 'roles,home_address' } }).subscribe((res: any) => {
        this.account = res.data;

        const currentOrganisation = this.authGuardService.getSessionData().user.organisations.find(value => {
          return parseInt(value.id, 10) === parseInt(this.userInformation.organisationId, 10);
        });

        this.organisationForm.patchValue({
          organisation_id: currentOrganisation.id,
        });

      });
    }
  }

  async getOrganisations(inputEvent?: any) {
    const params = { with: '', no_pagination: 1 };
    if (inputEvent && inputEvent.target && inputEvent.target.value) {
      params['name_like'] = inputEvent.target.value;
    }

    if (this.authGuardService.isSuperAdmin()) {
      await this.organisationService
        .getAll(params)
        .pipe(map(({ data }) => this.filterOrganisationsList(data)))
        .subscribe(result => {
          result.sort((a,b)=> (a['name'].toLowerCase() > b['name'].toLowerCase() ? 1 : -1));

          this.organisationsForUser = result;
        });
    } else {
      this.organisationsForUser = this.filterOrganisationsList(this.authGuardService.getSessionData().user.organisations);
    }

  }

  filterOrganisationsList(organisationsArray): Organisation[] {
    return Object.values(
      organisationsArray.reduce((acc, item) => {
        if (!acc[item.id]) {
          acc[item.id] = item;
        }
        return acc;
      }, {}),
    );
  }

  submitOrganisation() {
    if (!this.organisationForm.valid) {
      this.organisationForm.markAllAsTouched();
      this.organisationForm.markAsTouched();
      return;
    }

    const params = {
      firstname: this.account.firstname,
      lastname: this.account.lastname,
      organisation: this.organisationForm.value.organisation_id,
      email: this.account.email,
      logged_in_organisation_id: this.organisationForm.value.organisation_id,
      with: 'roles,home_address',
    };

    this.userService
      .updateUser(this.account.id, params)
      .pipe(switchMap(() => this.userService.getCurrentUserDetails()))
      .subscribe(
        (res: CurrentUserResponse) => {
          this.toastr.success(this.translate.instant('users.organisation_updated') + '!');
          this.authGuardService.setSessionData(res.data);
          this.helpers.changeOrganisationNameBreadcrumb();
          this.activeModal.dismiss();
        },
        err => {
          this.toastr.error(err.error.error.message);
        },
      );
  }

}

@Injectable({
  providedIn: 'root',
})
export class ChangeOrganisationDialogComponent {
  constructor(private modalService: NgbModal) {}

  confirm(options: Options) {
    const SUCCESS_BUTTON_STYLE = 'btn-success';
    const ref = this.modalService.open(ChangeOrganisationDialogContentComponent);
    ref.componentInstance.title = options.title;

    if (options.text) {
      ref.componentInstance.text = options.text;
    }

    if (options.item) {
      ref.componentInstance.item = options.item;
    }

    if (options.additionalText) {
      ref.componentInstance.additionalText = options.additionalText;
    }

    if (options.data) {
      ref.componentInstance.data = options.data;
    }

    ref.componentInstance.buttonStyle = options.buttonStyle ? `btn-${options.buttonStyle}` : SUCCESS_BUTTON_STYLE;

    if (options.cancelText) {
      ref.componentInstance.cancelText = options.cancelText;
    }
    ref.componentInstance.buttonText = options.buttonText;

    ref.result
      .then(
        () => {
          options.accept();
        },
        rejection => {
          return rejection !== 'button' && options.dismissRejection ? null : options.reject && options.reject();
        },
      )
      .catch(() => {
        return options.dismissRejection ? null : options.reject && options.reject();
      });
  }
}

export interface Options {
  title: string;
  text?: string;
  item?: string;
  buttonText: string;
  additionalText?: string;
  buttonStyle?: string;
  data?: string;
  accept: Function;
  reject?: Function;
  buttonGreen?: boolean;
  dismissRejection?: boolean;
  cancelText?: string;
  organisations?: Organisation[];
}

@NgModule({
  declarations: [ChangeOrganisationDialogContentComponent],
  imports: [TranslateModule, CommonModule, FontAwesomeModule, NgSelectModule, ReactiveFormsModule],
})
export class ChangeOrganisationDialogModule {}
