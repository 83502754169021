<p-accordion>
  <p-accordionTab header="{{ 'filters.filters' | translate }} {{ activeFiltersCount > 0 ? '(' + activeFiltersCount + ')' : '' }}" [selected]="true">
    <div class="container-fluid" id="ca-filters">
      <form class="row align-items-end" (ngSubmit)="onSubmit()">
        <div [class]="filter.size ? filter.size : filterSize.Small" *ngFor="let filter of visibleFilters">
          <div [id]="'filter-' + filter.id" *ngIf="isMultiSelectAddNew(filter)" class="form-group">
            <label translate>{{ filter.label }}</label>
            <ng-select
              class="custom-ng-select"
              [items]="filter.items"
              [addTag]="true"
              addTagText="{{ 'filters.add_tag_text' | translate }}"
              [multiple]="true"
              placeholder="{{ filter.placeholder | translate }}"
              notFoundText="{{ 'filters.add_tag_text' | translate }}"
              clearAllText="{{ 'filters.clear' | translate }}"
              (change)="onFilterChange(filter.id, $event)"
              [ngModel]="filter.selected"
              [ngModelOptions]="{ standalone: true }"
            >
            </ng-select>
          </div>

          <div [id]="'filter-' + filter.id" *ngIf="isMultiSelect(filter)" class="form-group">
            <label translate>{{ filter.label }}</label>
            <ng-select
              class="custom-ng-select"
              [items]="filter.items"
              [multiple]="true"
              placeholder="{{ filter.placeholder | translate }}"
              notFoundText="{{ 'filters.not_found' | translate }}"
              clearAllText="{{ 'filters.clear' | translate }}"
              (change)="onFilterChange(filter.id, $event)"
              [ngModel]="filter.selected"
              [ngModelOptions]="{ standalone: true }"
              bindValue="value"
            >
            </ng-select>
          </div>

          <div [id]="'filter-' + filter.id" *ngIf="isTextBox(filter)" class="form-group">
            <label translate>{{filter.label}}</label>
            <input
              type="text"
              class="form-control"
              placeholder="{{filter.placeholder | translate}}"
              [value]="filter.selected"
              (input)="onFilterChange(filter.id, $event)"
            >
          </div>

          <div [id]="'filter-' + filter.id" *ngIf="isSingleSelect(filter)" class="form-group">
            <label translate>{{ filter.label }}</label>
            <ng-select
              class="custom-ng-select"
              (change)="onFilterChange(filter.id, $event)"
              [items]="filter.items"
              [multiple]="false"
              [ngModelOptions]="{ standalone: true }"
              [ngModel]="filter.selected"
              clearAllText="{{ 'filters.clear' | translate }}"
              notFoundText="{{ 'filters.not_found' | translate }}"
              placeholder="{{ filter.placeholder | translate }}"
              bindValue="value"
            >
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <span class="ng-value-label" translate>{{item.label}}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div translate>{{item.label}}</div>
              </ng-template>
            </ng-select>
          </div>

          <div [id]="'filter-' + filter.id" *ngIf="isDateRange(filter)" class="form-group">
            <label translate>{{ filter.label }}</label>
            <app-date-range-selection
              [from]="filter.from"
              [to]="filter.to"
              [placeholder]="filter.placeholder"
              (dateRangeSelection)="onFilterChange(filter.id, $event)"
            ></app-date-range-selection>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 form-group">
          <button class="btn btn-primary" type="submit" translate>filters.filter</button>
        </div>
      </form>
    </div>
  </p-accordionTab>
</p-accordion>
