<form (ngSubmit)="search.emit(searchTerm)">
  <div class="form-group">
    <label *ngIf="label">{{label | translate}}</label>
    <div class="input-group mb-3">
      <input type="text" name="searchTerm" [(ngModel)]="searchTerm" class="form-control" placeholder="{{placeholder | translate}}" autofocus>
      <fa-icon *ngIf="searchTerm" (click)="empty()" class="form-control-clear" [icon]="['fal', 'times']" [fixedWidth]="true"></fa-icon>
        <button class="btn btn-primary rounded-end" type="submit"><fa-icon [icon]="['fal', 'search']"></fa-icon></button>
    </div>
  </div>
</form>
