import { Component, Input, NgModule, OnInit } from '@angular/core';
import { LayoutButton } from '../../layout/layout.component';
import { AuthGuardService } from '../../services/auth-guard.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PermissionsModule } from '../permissions/permissions.module';

@Component({
  selector: 'app-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {
  @Input() titleButtons?: Array<LayoutButton>;
  @Input() title: string;

  constructor(public authGuardService: AuthGuardService) {}

  ngOnInit() {}

  isSuperAdmin(): boolean {
    return this.authGuardService.isSuperAdmin();
  }
}
@NgModule({
  declarations: [PageTitleComponent],
  imports: [TranslateModule, CommonModule, RouterModule, PermissionsModule],
  exports: [PageTitleComponent],
})
export class PageTitleModule {}
