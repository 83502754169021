import { Component, Injectable, Input, NgModule, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Participant } from '../../models/participant.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogContentComponent implements OnInit {
  @Input() title = '';
  @Input() text = '';
  @Input() additionalText = '';
  @Input() data = '';
  @Input() item = '';
  @Input() buttonText = '';
  @Input() buttonStyle = '';
  @Input() cancelText = 'common.cancel';

  // Participant submitting details
  @Input() participants: Participant[];
  @Input() send_cards_to_participants: boolean;

  thereIsReducedPriceParticipants: boolean;
  listOfReducedPriceParticipants: Participant[];

  thereIsWithCardParticipants: boolean;
  listOfWithCardParticipants: Participant[];

  cardsAreSentToParticipants: boolean;
  REDUCED_PRICE = 1;

  // Checkbox inputs
  rightAmountOfParticipants = false;
  rightDetailsOnParticipants = false;
  rightAddressesOnParticipants = false;
  rightReducedPricesOnParticipants = false;
  rightWithCardOnParticipants = false;
  iAcceptTerms = false;

  constructor(public activeModal: NgbActiveModal, private translate: TranslateService, private toastr: ToastrService) {}

  ngOnInit() {
    this.thereIsReducedPriceParticipants = this.isThereReducedPriceParticipants();
    this.listOfReducedPriceParticipants = this.getListOfReducedPriceParticipants();

    this.thereIsWithCardParticipants = this.isThereWithCardParticipants();
    this.listOfWithCardParticipants = this.getListOfWithCardParticipants();

    this.cardsAreSentToParticipants = this.send_cards_to_participants;
  }

  submitParticipants() {
    if (
      !this.rightAmountOfParticipants ||
      !this.rightDetailsOnParticipants ||
      !this.rightReducedPricesOnParticipants ||
      !this.rightWithCardOnParticipants ||
      !this.iAcceptTerms ||
      (!this.rightAddressesOnParticipants && this.cardsAreSentToParticipants)
    ) {
      this.toastr.error(this.translate.instant('training-event.please_check_all_fields_before_proceeding'));
      return;
    }
    this.activeModal.close();
  }

  isThereReducedPriceParticipants() {
    if (!this.participants) {
      return false;
    }
    return this.participants.some(participant => participant.non_commercial === true);
  }

  getListOfReducedPriceParticipants() {
    if (!this.participants) {
      return [];
    }
    return this.participants.filter(participant => participant.non_commercial === true);
  }

  isThereWithCardParticipants() {
    if (!this.participants) {
      return false;
    }
    return this.participants.some(participant => participant.with_card === true);
  }

  getListOfWithCardParticipants() {
    if (!this.participants) {
      return [];
    }
    return this.participants.filter(participant => participant.with_card === true && participant.status !== 'deleted');
  }

  getAmountText() {
    if (!this.participants) {
      return 0;
    }
    const amount = this.participants.filter(value => {
      return value.status !== 'deleted';
    });
    return this.translate.instant('training-event.there_was_x_amount_of_people_in_this_course', { amount: amount.length });
  }
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogComponent {
  constructor(private modalService: NgbModal) {}

  confirm(options: Options) {
    const DANGER_BUTTON_STYLE = 'btn-danger';
    const ref = this.modalService.open(ConfirmationDialogContentComponent);
    ref.componentInstance.title = options.title;
    if (options.text) {
      ref.componentInstance.text = options.text;
    }
    if (options.item) {
      ref.componentInstance.item = options.item;
    }
    if (options.additionalText) {
      ref.componentInstance.additionalText = options.additionalText;
    }
    if (options.data) {
      ref.componentInstance.data = options.data;
    }
    if (options.participants) {
      ref.componentInstance.participants = options.participants;
    }
    ref.componentInstance.send_cards_to_participants = options.send_cards_to_participants;
    ref.componentInstance.buttonStyle = options.buttonStyle ? `btn-${options.buttonStyle}` : DANGER_BUTTON_STYLE;
    if (options.cancelText) {
      ref.componentInstance.cancelText = options.cancelText;
    }
    ref.componentInstance.buttonText = options.buttonText;
    ref.result
      .then(
        () => {
          options.accept();
        },
        rejection => {
          return rejection !== 'button' && options.dismissRejection ? null : options.reject && options.reject();
        },
      )
      .catch(() => {
        return options.dismissRejection ? null : options.reject && options.reject();
      });
  }
}

export interface Options {
  title: string;
  text?: string;
  item?: string;
  buttonText: string;
  additionalText?: string;
  buttonStyle?: string;
  data?: string;
  accept: Function;
  reject?: Function;
  buttonGreen?: boolean;
  dismissRejection?: boolean;
  cancelText?: string;
  participants?: Participant[];
  send_cards_to_participants?: boolean;
}

@NgModule({
  declarations: [ConfirmationDialogContentComponent],
  imports: [TranslateModule, CommonModule, FontAwesomeModule],
})
export class ConfirmationDialogModule {}
