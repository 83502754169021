import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutComponentComponent } from './layout-component.component';

@NgModule({
  declarations: [LayoutComponentComponent],
  imports: [TranslateModule, CommonModule],
  exports: [LayoutComponentComponent],
})
export class LayoutComponentModule {}
