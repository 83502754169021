<ul class="timeline">
  <li *ngIf="noteObject">
    <div class="timeline-badge bg-primary shadow"><fa-icon class="" [icon]="['fal', 'calendar-plus']" [fixedWidth]="true"></fa-icon></div>
    <div class="timeline-panel shadow">
      <div class="timeline-heading">
        <h4 class="timeline-title" translate>notes.add_note</h4>
      </div>
      <div class="timeline-body">
        <form [formGroup]="notesForm" role="form" (ngSubmit)="onSubmit()">
          <div class="form-group" >
            <label class="col-form-label col-form-label-sm" for="description" translate>common.notes</label>
              <textarea class="form-control bg-transparent"
                        formControlName="description"
                        name="description"
                        id="description"
                        placeholder="{{'notes.add_note_placeholder' | translate}}"
                        rows="7"></textarea>
          </div>
          <div class="text-right mt-2">
            <button type="submit" class="btn btn-primary" translate>common.save</button>
          </div>
        </form>
      </div>
    </div>
  </li>

  <li *ngFor="let note of notes">
    <div class="timeline-badge bg-secondary shadow">{{note.user.firstname.charAt(0).toLocaleUpperCase()}}{{note.user.lastname.charAt(0).toLocaleUpperCase()}}</div>
    <div class="timeline-panel shadow">
      <div class="timeline-heading mb-1">
        <h4 class="timeline-title">{{note.user.firstname}} {{note.user.lastname}}</h4>
        <div class="d-flex justify-content-between">
          <small class="text-muted">{{note.created_at | date:'medium'}}</small>
          <small *ngIf="note.created_at != note.updated_at" class="text-muted">(<span translate>common.updated</span> {{note.created_at | date:'medium'}})</small>
        </div>
      </div>
      <div class="timeline-body">
        <p>{{ note.description}}</p>
      </div>
    </div>
  </li>

</ul>
