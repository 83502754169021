<div class="row pt-2" *ngIf="collectionSize">
  <div class="col-12 col-md-3 d-flex align-items-center justify-content-start">
    <p [innerHTML]="getTranslatedPaginationText()"></p>
  </div>
  <div class="col-12 col-md-6 d-flex align-items-center justify-content-center">
    <ngb-pagination [collectionSize]="collectionSize"
                    [(page)]="page"
                    [maxSize]="maxSize"
                    [rotate]="rotate"
                    [pageSize]="pageSize"
                    [directionLinks]="directionLinks"
                    (pageChange)="pageChange.emit({pageSize: pageSize, page: page})">
    </ngb-pagination>
  </div>
  <div class="col-12 col-md-3 d-flex align-items-center justify-content-end">
    <div ngbDropdown>
      <ng-select id="ng-select-active-selection" [clearable]="false" (change)="newSelection()" [items]="selections" [(ngModel)]="activeSelection"></ng-select>
    </div>
  </div>
  <div class="col-12 pb-2">
    <ng-content></ng-content>
  </div>
  <div class="col-12 d-flex align-items-center justify-content-around">
    <ngb-pagination [collectionSize]="collectionSize"
                    [(page)]="page"
                    [maxSize]="maxSize"
                    [pageSize]="pageSize"
                    [directionLinks]="directionLinks"
                    (pageChange)="pageChange.emit({pageSize: pageSize, page: page})">
    </ngb-pagination>
  </div>
</div>
