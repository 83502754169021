export interface BaseFilter {
  id: string;
  type: FilterType;
  label: string;
  placeholder: string;
  hidden?: boolean;
  selected?: any[] | {};
  size?: FilterSize;
  sortBy?: boolean;
}

export interface MultiSelectAddNewFilter extends BaseFilter {
  items?: Item;
  selected?: any;
  translateItemLabels?: boolean;
  itemType?: ItemType;

  filterMethod?(selected: any, item: any): boolean;
}

export interface MultiSelectFilter extends BaseFilter {
  items?: Item[];
  selected?: any[];
  translateItemLabels?: boolean;
  itemType?: ItemType;

  filterMethod?(selected: any[], item: any): boolean;
}

export interface SingleSelectFilter extends BaseFilter {
  items?: Item[];
  selected?: any;
  translateItemLabels?: boolean;
  itemType?: ItemType;

  filterMethod?(selected: any[], item: any): boolean;
}

export interface TextBoxFilter extends BaseFilter {
  items?: Item;
  selected?: any;
  translateItemLabels?: boolean;
  itemType?: ItemType;

  filterMethod?(selected: any, item: any): boolean;
}

export interface DateRangeFilter extends BaseFilter {
  from?: Date;
  to?: Date;

  filterMethod?(from: Date, to: Date, item: any): boolean;
}

export type Filter = MultiSelectAddNewFilter | MultiSelectFilter | DateRangeFilter | TextBoxFilter;

export enum FilterType {
  SingleSelect,
  MultiSelectAddNew,
  MultiSelect,
  DateRange,
  TextBox,
  DateRangeWithSelection,
}

export interface Item {
  label: string;
  value: any;
}

export enum FilterSize {
  Small = 'col-12 col-md-6 col-lg-4 col-xl-3',
  Medium = 'col-12 col-md-12 col-lg-6 col-xl-4',
  Large = 'col-12 col-md-12 col-lg-9 col-xl-6',
}

export enum ItemType {
  String,
  Number,
}
