import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Training } from '../models/training.model';

export interface GetAllResponse {
  status: number;
  success: boolean;
  data: any[];
}

@Injectable({
  providedIn: 'root',
})
export class BillingTrainingService {
  url = `${environment.apiUrl}v1/billingtrainings`;

  constructor(private http: HttpClient) {}

  getAll(params: {}) {
    return this.http.get<GetAllResponse>(this.url, params);
  }
}
