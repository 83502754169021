import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DateRangeFilter,
  Filter,
  FilterType,
  ItemType,
  MultiSelectAddNewFilter,
  MultiSelectFilter,
  SingleSelectFilter,
  TextBoxFilter,
} from './filters';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  constructor(private route: ActivatedRoute, private router: Router, private location: Location) {}

  fillSelectedFromUrl(filters: Filter[]): Filter[] {
    const updated = [...filters];

    const keysToGet = filters.map(filter => filter.id);

    this.route.snapshot.queryParamMap.keys
      .filter(key => keysToGet.includes(key))
      .forEach(key => {
        const values = this.route.snapshot.queryParamMap.getAll(key);
        const filterToUpdate = updated.find(filter => filter.id === key);

        switch (filterToUpdate.type) {
          case FilterType.MultiSelectAddNew:
            (filterToUpdate as MultiSelectAddNewFilter).selected = values
              .map(value => this.parse(filterToUpdate, value))
              .filter(value => value !== null && value !== '');
            break;
          case FilterType.MultiSelect:
            (filterToUpdate as MultiSelectFilter).selected = values
              .map(value => this.parse(filterToUpdate, value))
              .filter(value => value !== null && value !== '');
            break;
          case FilterType.SingleSelect:
            (filterToUpdate as SingleSelectFilter).selected = values
              .map(value => this.parse(filterToUpdate, value))
              .filter(value => value !== null && value !== '')[0];
            break;
          case FilterType.TextBox:
            (filterToUpdate as MultiSelectFilter).selected = values.filter(value => value !== null && value !== '');
            break;
          case FilterType.DateRange:
            const parts = values[0] && values[0].split('_');
            const f = filterToUpdate as DateRangeFilter;
            f.from = parts && parts[0] && this.parseDate(parts[0]);
            f.to = parts && parts[1] && this.parseDate(parts[1]);
            break;
        }
      });

    return updated;
  }

  convertToBackendParams(filters: Filter[], isSingle = []) {
    return this.convertToParams(filters, true, isSingle);
  }

  putFiltersToUrl(filters: Filter[]) {
    // If there is pagination params save them
    const paginationParams = {};
    for (const key of this.route.snapshot.queryParamMap.keys) {
      if (key === 'pageSize' || key === 'page' || key === 'sort') {
        paginationParams[key] = this.route.snapshot.queryParamMap.getAll(key);
      }
    }
    this.router.navigate([], { relativeTo: this.route, queryParams: { ...this.convertToParams(filters), ...paginationParams } });
  }

  updateFilters(filters: Filter[], updates: Filter[]): Filter[] {
    const updated = [...filters];
    return updated.map(filter => {
      let modified: any = filter;
      updates.forEach(updateFilter => {
        if (updateFilter.id === filter.id) {
          modified = { ...modified, ...updateFilter };
        }
      });
      return modified;
    });
  }

  filterData(filters: Filter[], data: any[]): any[] {
    return (
      data &&
      data.filter(item => {
        let keep = true;
        filters.forEach(filter => {
          if (filter.filterMethod) {
            switch (filter.type) {
              case FilterType.MultiSelect: {
                const f = filter as MultiSelectFilter;
                if (f.selected && f.selected.length && !f.filterMethod(f.selected, item)) {
                  keep = false;
                }
                break;
              }

              case FilterType.MultiSelectAddNew: {
                const f = filter as MultiSelectAddNewFilter;
                if (f.selected && f.selected.length && !f.filterMethod(f.selected, item)) {
                  keep = false;
                }
                break;
              }

              case FilterType.TextBox: {
                const f = filter as TextBoxFilter;
                if (f.selected && f.selected.length && !f.filterMethod(f.selected, item)) {
                  keep = false;
                }
                break;
              }
              case FilterType.SingleSelect: {
                const f = filter as MultiSelectFilter;
                if (f.selected && f.selected.length && !f.filterMethod(f.selected, item)) {
                  keep = false;
                }
                break;
              }

              case FilterType.DateRange: {
                const f = filter as DateRangeFilter;
                if (f.from && !f.filterMethod(f.from, f.to, item)) {
                  keep = false;
                }
                break;
              }
            }
          }
        });
        return keep;
      })
    );
  }

  private parse(filter: Filter, value: string): any {
    switch (filter.type) {
      case FilterType.MultiSelectAddNew:
        switch ((filter as MultiSelectAddNewFilter).itemType) {
          case ItemType.Number:
            const parsed = parseInt(value, 10);
            return isNaN(parsed) ? null : parsed;
          default:
            return value;
        }

      case FilterType.MultiSelect:
        switch ((filter as MultiSelectFilter).itemType) {
          case ItemType.Number:
            const parsed = parseInt(value, 10);
            return isNaN(parsed) ? null : parsed;
          default:
            return value;
        }

      case FilterType.SingleSelect:
        switch ((filter as SingleSelectFilter).itemType) {
          case ItemType.Number:
            const parsed = parseInt(value, 10);
            return isNaN(parsed) ? null : parsed;
          default:
            return value;
        }
      case FilterType.DateRange:
        return this.parseDate(value);
    }
  }

  private formatDate(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }

  private parseDate(str: string): Date {
    const date = moment(str, 'YYYY-MM-DD');
    return date.isValid() ? date.toDate() : undefined;
  }

  private convertToParams(filters: Filter[], forLaravel = false, isSingle = []) {
    const params = {};
    filters.forEach(filter => {
      switch (filter.type) {
        case FilterType.MultiSelectAddNew: {
          const f = filter as MultiSelectAddNewFilter;
          if (f.selected && f.selected.length) {
            if (forLaravel) {
              params[`${f.id}[]`] = f.selected;
            } else {
              params[f.id] = f.selected;
            }
          }
          break;
        }
        case FilterType.MultiSelect: {
          const f = filter as MultiSelectFilter;
          if (f.selected && f.selected.length) {
            if (forLaravel) {
              params[`${f.id}[]`] = f.selected;
            } else {
              params[f.id] = f.selected;
            }
          }
          break;
        }
        case FilterType.TextBox: {
          const f = filter as MultiSelectFilter;
          if (f.selected && f.selected.length) {
            params[f.id] = f.selected;
          }
          break;
        }
        case FilterType.SingleSelect: {
          const f = filter as SingleSelectFilter;
          if (f.selected) {
            if (forLaravel) {
              if (isSingle.includes(f.id)) {
                params[`${f.id}`] = f.selected;
              } else {
                params[`${f.id}[]`] = f.selected;
              }
            } else {
              params[f.id] = f.selected;
            }
          }
          break;
        }
        case FilterType.DateRange: {
          const f = filter as DateRangeFilter;
          if (f.from) {
            params[f.id] = f.to ? `${this.formatDate(f.from)}_${this.formatDate(f.to)}` : this.formatDate(f.from);
          }
        }
      }
    });
    return params;
  }
}
