import { Component, Injectable, Input, NgModule } from '@angular/core';
import { FileStatus } from '../file-upload/file-upload.component';
import { FileAttachmentService } from '../../services/file-attachment.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileAttachment } from '../../models/file-attachment.model';

@Component({
  selector: 'app-file-add-modal',
  templateUrl: './file-add-modal.component.html',
  styleUrls: ['./file-add-modal.component.scss'],
})
export class FileAddModalContentComponent {
  @Input() modelId: string;
  @Input() modelType: 'event' | 'training_template' | 'location';
  @Input() file: File;
  @Input() label: 'trainee' | 'trainer';
  @Input() fileArray: Array<FileAttachment>;
  @Input() organisationId: number;
  @Input() event: boolean;
  uploadFiles: FileStatus[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    public fileAttachmentService: FileAttachmentService,
    public toastr: ToastrService,
    public translateService: TranslateService,
  ) {
  }

  addFile(file: File, label: 'trainee' | 'trainer', files: FileAttachment[]) {
    const type = file.type.includes('image') ? 'image' : 'document';
    this.fileAttachmentService.addFile([file], this.organisationId, type).subscribe(
      res => {
        /*
          if (res.status === 400 || res.status === 401 || res.status === 404) {
            this.uploadFiles.filter(currentFile => currentFile.file.fileEntry.name !== file.name);
          } else
          */
        if (res.status === 'progress') {
          const uploadedFile = this.uploadFiles.find(currentFile => currentFile.file.fileEntry.name === file.name);
          if (uploadedFile) {
            uploadedFile['progress'] = res.message;
          }
        }
        if (res.status === 201 && res.data && res.data.length > 0) {
          if (this.modelId) {
            this.attachFile(file, label, res.data[0].id);
          } else {
            this.fileArray.push({
              id: res.data[0].id,
              name: file.name,
              url: '',
              pivot: {
                id: 0,
                label: 'not_attached',
              },
            });
            this.toastr.success(this.translateService.instant('files.file_added'));
          }
        }
      },
      err => {
        this.toastr.error(err.error.message);
        this.uploadFiles.filter(currentFile => currentFile.file.fileEntry.name !== file.name);
      },
    );
  }
  attachFile(file: File, label: 'trainee' | 'trainer', fileId) {
    this.fileAttachmentService.attachFile(this.modelType, this.modelId, [fileId], label).subscribe(
      res2 => {
        if (label === 'trainee') {
          this.fileArray.push(res2.data.trainee_attachments[res2.data.trainee_attachments.length - 1]);
        } else if (label === 'trainer') {
          this.fileArray.push(res2.data.trainer_attachments[res2.data.trainer_attachments.length - 1]);
        }
        this.toastr.success(this.translateService.instant('files.file_added'));
      },
      (error: any) => {
        this.toastr.error(error?.error?.message);
      },
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class FileAddModalComponent {
  organisationId: number;

  constructor(private modalService: NgbModal) {
  }

  open(options: FileOptions) {
    const sessionData = JSON.parse(localStorage.getItem('session'));

    if (sessionData) {
      this.organisationId = sessionData.user.logged_in_organisation.id;
    }
    const ref = this.modalService.open(FileAddModalContentComponent);
    if (options.modelId) {
      ref.componentInstance.modelId = options.modelId;
    }
    ref.componentInstance.modelType = options.modelType;
    ref.componentInstance.label = options.label;
    ref.componentInstance.fileArray = options.fileArray;
    // ref.componentInstance.event = options.event;
    ref.componentInstance.organisationId = this.organisationId;
  }
}

export interface FileOptions {
  modelId?: string;
  modelType: 'event' | 'training_template' | 'location';
  label: 'trainee' | 'trainer';
  fileArray: Array<any>;
}
@NgModule({
  declarations: [FileAddModalContentComponent],
  imports: [TranslateModule, CommonModule, FontAwesomeModule, FileUploadModule],
})
export class FileAddModalModule {}
