<ng-container *ngIf="!valueArray && !loopValueArray">
    <div class="row">
        <div class="col-5">
            <p class="text-muted label">{{ label | translate}}</p>
        </div>
        <div class="col-7">
            <p [ngClass]="extraStyle ? extraStyle : null">{{ value }}</p>
        </div>
    </div>
    <hr class="mt-0 mb-3">
</ng-container>

<ng-container *ngIf="loopValueArray">
    <div class="row">
        <div class="col-5">
            <p class="text-muted label">{{label | translate}}</p>
        </div>
        <div class="col-7">
            <ul class="list-unstyled">
                <li *ngFor="let req of loopValueArray">{{loopTranslatePrefix ? ((loopTranslatePrefix + '.' + req[loopName]) | translate) : (req[loopName])}}</li>
            </ul>
        </div>
    </div>
    <hr class="mt-0 mb-3">
</ng-container>

<ng-container *ngIf="valueArray">
    <div *ngIf="header" class="row mb-4">
        <div class="col">
            <h4 translate>{{header}}</h4>
        </div>
    </div>
    <ng-container *ngFor="let valueA of valueArray; let i = index">
        <div class="row">
            <div class="col-5">
                <p class="text-muted label">{{ labelArray[i] | translate}}</p>
            </div>
            <div class="col-7">
                <p [ngClass]="extraStyle ? extraStyle : null">{{ valueA }}</p>
            </div>
        </div>
        <hr class="mt-0 mb-3">
    </ng-container>
</ng-container>
