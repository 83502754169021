import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';

export interface FileStatus {
  file: NgxFileDropEntry;
  progress: number;
  id?: number;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Input() files: FileStatus[];
  @Output() uploadFile = new EventEmitter<any>();
  readonly allowedFiles = ['doc', 'docx', 'ppt', 'pptx', 'pdf', 'png', 'jpg', 'gif', 'zip', 'mp4'];

  constructor(public translate: TranslateService, private toastr: ToastrService) {
  }

  addFiles(files) {
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      if (fileEntry.isFile) {
        if (!this.files.some(file => file.file && file.file.fileEntry.name === fileEntry.name)) {
          if (this.isFileAllowed(fileEntry.name)) {
            this.files.push({ file: droppedFile, progress: 0, id: 0 });
            fileEntry.file(file => {
              this.uploadFile.emit(file);
            });
          } else {
            this.toastr.warning(this.translate.instant('files.file_format_not_supported'));
          }
        } else {
          this.toastr.warning(this.translate.instant('files.file_already_exists'));
        }
      }
    }
  }

  private isFileAllowed(fileName: string) {
    const [fileExt] = fileName.split('.').reverse();

    return this.allowedFiles.includes(fileExt.trim().toLowerCase());
  }
}
