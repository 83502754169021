import { Component, Injectable, Input, NgModule, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../../../models/user.model';

@Component({
  templateUrl: './merge-confirmation-dialog.component.html',
  styleUrls: ['./merge-confirmation-dialog.component.scss'],
})
export class MergeConfirmationDialogContentComponent implements OnInit {
  @Input() title: string;
  @Input() userToKeep: User;
  @Input() userToMerge: User;

  constructor(public activeModal: NgbActiveModal, private translate: TranslateService, private toastr: ToastrService) {}

  ngOnInit() {}

  getRolesAsString(rolesDetails) {
    if (!rolesDetails) {
      return '';
    }
    return rolesDetails.map(rd => this.translate.instant('roles.' + rd.role)).toString();
  }
}

@Injectable({
  providedIn: 'root',
})
export class MergeConfirmationDialogComponent {
  constructor(private modalService: NgbModal) {}

  confirm(options: Options) {
    const ref = this.modalService.open(MergeConfirmationDialogContentComponent, { size: 'lg' });
    ref.componentInstance.title = options.title;
    ref.componentInstance.userToKeep = options.userToKeep;
    ref.componentInstance.userToMerge = options.userToMerge;
    ref.result
      .then(
        () => {
          options.accept();
        },
        rejection => {
          return rejection !== 'button' && options.dismissRejection ? null : options.reject && options.reject();
        },
      )
      .catch(() => {
        return options.dismissRejection ? null : options.reject && options.reject();
      });
  }
}

export interface Options {
  title: string;
  userToKeep: User;
  userToMerge: User;
  accept: Function;
  reject?: Function;
  dismissRejection?: boolean;
  cancelText?: string;
}

@NgModule({
  declarations: [MergeConfirmationDialogContentComponent],
  imports: [TranslateModule, CommonModule, FontAwesomeModule],
})
export class MergeConfirmationDialogModule {}
