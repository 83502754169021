<div class="row" *ngIf="trainingTemplate">
    <div class="col">
        <app-layout-component [header]="'training-event.template_details'" [valueArray]="[
        trainingTemplate.public_name,
        trainingTemplate.private_name
        ]" [labelArray]="[
        'training-template.public_name',
        'training-template.private_name'
        ]"></app-layout-component>
        <app-layout-component *ngIf="trainingTemplate.duration_in_minutes"
                                  [value]="trainingTemplateService.getTemplateDuration(trainingTemplate.duration_in_minutes)"
                                  [label]="'training-template.duration'"></app-layout-component>
        <app-layout-component *ngIf="selectedEvent" [value]="getLastSubmitDate()" [label]="'training-event.last_date_to_submit_event'"></app-layout-component>
        <app-layout-component *ngIf="selectedEvent" [value]="getLastParticipantsSubmitDate()" [label]="'training-event.last_date_to_submit_participants'"></app-layout-component>
        <app-layout-component [value]="trainingTemplateService.getCalculatedPrice(trainingTemplate.price_in_cents_without_vat) + '€'" [label]="'training-template.calculated_price'"></app-layout-component>

        <app-layout-component *ngIf="trainer_requirements.length > 0"
                              [label]="'training-template.requires_approval_from_certification_body'"
                              [loopName]="'private_name'"
                              [loopValueArray]="trainer_requirements"
        ></app-layout-component>

        <app-layout-component *ngIf="trainee_requirements.length > 0"
                              [label]="'training-template.requirements_for_trainee'"
                              [loopName]="'private_name'"
                              [loopValueArray]="trainee_requirements"
        ></app-layout-component>

        <app-layout-component *ngIf="trainer_requirements.length > 0" [value]="trainingTemplate.description" [label]="'training-template.description'"></app-layout-component>
    </div>
</div>
