<div class="modal-header border-0 pt-4 px-4 mx-2 pb-1">
  <h1 class="modal-title h4 w-100" id="modal-basic-title">{{ listingTitle }}</h1>
  <div class="close pointer" (click)="activeModal.dismiss()">
    <fa-icon [icon]="['fal', 'times']" [fixedWidth]="true"></fa-icon>
  </div>


</div>

<div class="modal-body border-0 px-4 mx-2 mb-4">

  <div class="d-block my-4" style="overflow-x: scroll" >
    <table class="table">
      <thead>
      <tr>
        <th><span>ID</span></th>
        <th><span translate>common.firstname</span></th>
        <th><span translate>common.lastname</span></th>
        <th><span translate>training-code.template_name</span></th>
        <th><span translate>training-counts.statistics_at</span></th>
        <th><span translate>training-event.show.event_id</span></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let training of trainings">
        <td><span (click)="openTraining(training.id)" class="text-primary pointer">{{ training.id }}</span></td>
        <td>{{ training.user.firstname }}</td>
        <td>{{ training.user.lastname }}</td>
        <td>{{ training.template.public_name }}</td>
        <td>{{ training.statistics_at | date:'dd.MM.yyyy' }}</td>
        <td><span *ngIf="training.event" (click)="openEvent(training.event.id)" class="text-primary pointer">{{ training.event.id }}</span></td>
      </tr>
      </tbody>
    </table>
  </div>

</div>

<div class="modal-footer justify-content-end border-0 px-4 mx-2 mb-4">
  <div class="btn btn-outline-secondary pointer" (click)="activeModal.dismiss()" translate>common.close</div>
</div>
