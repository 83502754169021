<div class="modal-header border-0 pt-4 px-4 mx-2 pb-1">
  <h1 class="modal-title h4 w-100 text-center" id="modal-basic-title" translate>{{ title }}</h1>
  <div class="close" (click)="activeModal.dismiss()">
    <fa-icon [icon]="['fal', 'times']" [fixedWidth]="true"></fa-icon>
  </div>
</div>
<div class="modal-body border-0 px-4 mx-2">
  <div class="text-center">
    <div>
      <span *ngIf="text" class="custom-pre-wrap" translate>{{ text }}</span>
      <span *ngIf="item">:</span>
      <span *ngIf="data" class="font-weight-bolder">{{ data }}</span>
      <span class="py-3" *ngIf="additionalText">
        <span translate>{{ additionalText }}</span>
      </span>
      <form action="" class="text-left mt-4" *ngIf="participants">
        <div class="form-check text-left pb-3">
          <input (change)="rightAmountOfParticipants = !rightAmountOfParticipants" [checked]="rightAmountOfParticipants" type="checkbox" class="form-check-input participant-checkbox" id="participant_amount_check">
          <label class="form-check-label" [innerHTML]="getAmountText()" for="participant_amount_check"></label>
        </div>
        <div class="form-check text-left pb-3">
          <input (change)="rightDetailsOnParticipants = !rightDetailsOnParticipants" [checked]="rightDetailsOnParticipants" type="checkbox" class="form-check-input participant-checkbox" id="participants_correct_details_check">
          <label class="form-check-label" for="participants_correct_details_check" translate>training-event.every_participant_has_correct_details</label>
        </div>
        <div class="form-check text-left pb-3" *ngIf="cardsAreSentToParticipants">
          <input (change)="rightAddressesOnParticipants = !rightAddressesOnParticipants" [checked]="rightAddressesOnParticipants" type="checkbox" class="form-check-input participant-checkbox" id="participants_have_right_address_check">
          <label class="form-check-label" for="participants_have_right_address_check" translate>training-event.participants_have_right_address_check</label>
        </div>
        <div class="form-check text-left pb-3">
          <input (change)="rightReducedPricesOnParticipants = !rightReducedPricesOnParticipants" [checked]="rightReducedPricesOnParticipants" type="checkbox" class="form-check-input participant-checkbox" id="participants_with_reduced_price_are_correct">
          <label class="form-check-label" for="participants_with_reduced_price_are_correct" translate>training-event.participants_with_reduced_price_are_correct</label>
        </div>
        <p class="pb-1" *ngIf="thereIsReducedPriceParticipants"><span translate>training-event.list_of_reduced_price_participants</span>:</p>
        <ul class="pb-3 list-unstyled" *ngIf="thereIsReducedPriceParticipants">
          <li class="font-weight-bold" *ngFor="let participant of listOfReducedPriceParticipants">
            {{participant.firstname}} {{participant.lastname}}
          </li>
        </ul>
        <div class="form-check text-left pb-3">
          <input (change)="rightWithCardOnParticipants = !rightWithCardOnParticipants" [checked]="rightWithCardOnParticipants" type="checkbox" class="form-check-input participant-checkbox" id="participants_with_card_are_correct">
          <label class="form-check-label" for="participants_with_card_are_correct" translate>training-event.participants_with_card_are_correct</label>
        </div>
        <p class="pb-1" *ngIf="thereIsWithCardParticipants"><span translate>training-event.list_of_with_card_participants</span>:</p>
        <ul class="pb-3 list-unstyled" *ngIf="thereIsWithCardParticipants">
          <li class="font-weight-bold" *ngFor="let participant of listOfWithCardParticipants">
            {{participant.firstname}} {{participant.lastname}}
          </li>
        </ul>
        <div class="form-check text-left pb-3">
          <input (change)="iAcceptTerms = !iAcceptTerms" [checked]="iAcceptTerms" type="checkbox" class="form-check-input participant-checkbox" id="confirmation_text">
          <label class="form-check-label" for="confirmation_text" translate>training-event.participants_confirmation_text</label>
        </div>
      </form>
    </div>
    <div *ngIf="item">
      <span class="font-weight-bolder">{{ item }}</span>&nbsp;?
    </div>
  </div>
</div>
<!--<div class="modal-footer justify-content-center border-0 pb-4">-->
<!--  <button type="button" class="btn btn-outline-secondary me-3" (click)="activeModal.dismiss()" translate>{{ cancelText }}</button>-->
<!--  <button *ngIf="!participants" type="button" class="btn ms-1" [ngClass]="buttonStyle" (click)="activeModal.close()">-->
<!--    <span translate>{{ buttonText }}</span>-->
<!--  </button>-->
<!--  <button *ngIf="participants" type="button" class="btn ms-1" [ngClass]="buttonStyle" (click)="submitParticipants()">-->
<!--    <span translate>{{ buttonText }}</span>-->
<!--  </button>-->
<!--</div>-->

<div class="modal-footer justify-content-end border-0 px-4 mx-2 mb-4">
  <button *ngIf="participants" class="btn btn-primary me-1 pointer" (click)="submitParticipants()" translate>{{ buttonText }}</button>
  <button *ngIf="!participants"class="btn btn-primary me-1 pointer" (click)="activeModal.close()" translate>{{ buttonText }}</button>
  <div class="btn btn-outline-secondary pointer" (click)="activeModal.dismiss()" translate>common.cancel</div>
</div>
