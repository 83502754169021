import { Component, Injectable, Input, NgModule, SecurityContext } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss'],
})
export class PromptDialogContentComponent {
  @Input() title = '';
  @Input() htmlText = '';
  @Input() prompt = '';
  @Input() buttonText = '';
  @Input() cancelText = '';
  @Input() answer = '';

  constructor(public activeModal: NgbActiveModal) {}
}

@Injectable({
  providedIn: 'root',
})
export class PromptDialogComponent {
  constructor(private modalService: NgbModal, private sanitizer: DomSanitizer) {}

  confirm(options: Options) {
    const ref = this.modalService.open(PromptDialogContentComponent);
    ref.componentInstance.title = options.title;
    ref.componentInstance.htmlText = this.sanitizer.sanitize(SecurityContext.HTML, options.htmlText);
    ref.componentInstance.prompt = options.prompt;
    ref.componentInstance.buttonText = options.buttonText;
    ref.componentInstance.answer = options.answer;
    ref.componentInstance.cancelText = 'common.cancel';
    ref.result
      .then(result => options.accept(result), () => options.reject && options.reject())
      .catch(() => options.reject && options.reject());
  }
}

export interface Options {
  title: string;
  htmlText?: string;
  prompt?: string;
  buttonText: string;
  accept: Function;
  reject?: Function;
  answer?: string;
}

@NgModule({
  declarations: [PromptDialogContentComponent],
  imports: [TranslateModule, CommonModule, FontAwesomeModule],
})
export class PromptDialogModule {}
