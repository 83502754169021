import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FilehandlerService {
  constructor() {
  }

  downloadFile(file) {
    window.open(file.url);
  }

  sumFiles(fileList: Array<any>) {
    let sum = 0;
    fileList.map(value => {
      sum += value.size;
    });
    return this.handleFileSize(sum);
  }

  handleFileSize(fileSize) {
    if (fileSize > 1000000000) {
      return {
        fileSize: (fileSize / 1000000000).toFixed(2),
        filePrefix: 'training-event.size_gb',
      };
    } else if (fileSize > 1000000) {
      return {
        fileSize: (fileSize / 1000000).toFixed(2),
        filePrefix: 'training-event.size_mb',
      };
    } else {
      return {
        fileSize: (fileSize / 1000).toFixed(2),
        filePrefix: 'training-event.size_kb',
      };
    }
  }
}
