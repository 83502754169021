import { Component, EventEmitter, Input, NgModule, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDatepickerModule, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-date-range-selection',
  templateUrl: './date-range-selection.component.html',
  styleUrls: ['./date-range-selection.component.scss'],
})
export class DateRangeSelectionComponent implements OnInit {
  @Output() dateRangeSelection = new EventEmitter<{ from: Date; to: Date }>();
  @Input() from: Date;
  @Input() to: Date;
  @Input() placeholder = 'filters.select_dates';
  @ViewChild('dp', { static: false }) private datePicker: NgbInputDatepicker;
  hoveredDate: Date;

  constructor() {}

  ngOnInit() {}

  private emit(close?: boolean) {
    const dateRange = {
      from: this.from,
      to: this.to,
    };

    this.dateRangeSelection.emit(dateRange);

    if (close) {
      this.datePicker.close();
    }
  }

  private format(date: Date) {
    return moment(date).format('DD.MM.YYYY');
  }

  get formattedDateRange(): string {
    if (!this.from) {
      return null;
    }
    const fromFormatted = this.format(this.from);
    return this.to ? `${fromFormatted} - ${this.format(this.to)}` : `${fromFormatted}`;
  }

  onDateSelection(date: NgbDateStruct) {
    if (!this.from && !this.to) {
      this.from = this.toDate(date);
    } else if (this.from && !this.to && this.toMoment(date).isAfter(this.from)) {
      this.to = this.toDate(date);
      this.emit(true);
    } else {
      this.to = null;
      this.from = this.toDate(date);
    }
  }

  onInputChanged(event) {
    // just prevent manual input for now (needs parsing & validation)
    event.stopPropagation();
  }

  onClose() {
    this.emit();
  }

  onClear() {
    this.from = null;
    this.to = null;
    this.emit();
  }

  toDate(dateStruct: NgbDateStruct): Date {
    return dateStruct ? new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day) : null;
  }

  toMoment(dateStruct: NgbDateStruct): moment.Moment {
    return moment(this.toDate(dateStruct));
  }

  isHovered(date: NgbDateStruct) {
    return (
      this.from && !this.to && this.hoveredDate && this.toMoment(date).isAfter(this.from) && this.toMoment(date).isBefore(this.hoveredDate)
    );
  }

  isInside(date: NgbDateStruct) {
    return this.toMoment(date).isAfter(moment(this.from).startOf('day')) && this.toMoment(date).isBefore(moment(this.to).startOf('day'));
  }

  isFrom(date: NgbDateStruct) {
    return this.toMoment(date).isSame(this.from, 'd');
  }

  isTo(date: NgbDateStruct) {
    return this.toMoment(date).isSame(this.to, 'd');
  }
}

@NgModule({
  declarations: [DateRangeSelectionComponent],
  imports: [TranslateModule, FontAwesomeModule, NgbDatepickerModule, CommonModule],
  exports: [DateRangeSelectionComponent],
})
export class DateRangeSelectionModule {}
