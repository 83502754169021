import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() pageSize: number; // How many rows we wan't to show per page
  @Input() collectionSize: number; // How many rows there are in total
  @Input() directionLinks = true;
  @Input() page = 1; // On which page we are currently
  maxSize = 4;
  rotate = true;
  @Output() pageChange = new EventEmitter();
  @Output() newPageSizeSelection = new EventEmitter();
  activeSelection = 50;
  selections = [25, 50, 75, 100, 200, 500];

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    if (this.pageSize) {
      this.activeSelection = this.pageSize;
    }
  }

  clamp(number) {
    if (number > this.collectionSize) {
      return this.collectionSize;
    }
    return number;
  }

  newSelection() {
    this.newPageSizeSelection.emit(this.activeSelection);
  }

  getTranslatedPaginationText() {
    return this.translate.instant('pagination.showing_x_items_of', {
      from: (this.page - 1) * this.pageSize + 1,
      to: this.clamp((this.page - 1) * this.pageSize + this.pageSize),
      total: this.collectionSize,
    });
  }
}
