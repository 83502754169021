<div>
    <ul class="list-unstyled text-left my-2">
        <li *ngFor="let val of validations" class="mt-1" [ngClass]="val.completed ? 'text-primary' : 'text-muted'">
                <span>
                  <fa-icon *ngIf="!val.completed" [icon]="['fal', 'info-circle']"></fa-icon>
                  <fa-icon *ngIf="val.completed" [icon]="['fal', 'check-circle']"></fa-icon>
                </span>
            <span class="ms-2" translate>{{ val.text }}</span>
        </li>
    </ul>
</div>
