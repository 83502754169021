<hr class="my-4">
<div class="row">
  <div class="col-12">
    <div class="d-flex justify-content-between buttons">
      <div class="left">
        <ng-content select="span.primary"></ng-content>
      </div>
      <div class="right">
        <ng-content select="span.others"></ng-content>
      </div>
    </div>
  </div>
</div>
