import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Note, NoteObject } from '../../models/note.model';
import { ValidationMessagesService } from '../../services/validation-messages.service';
import { NoteService } from '../../services/note.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notes-timeline',
  templateUrl: './notes-timeline.component.html',
  styleUrls: ['./notes-timeline.component.scss'],
})
export class NotesTimelineComponent implements OnInit {
  @Input() notes: Note[];
  @Input() noteObject: NoteObject;
  noteables: any[] = [];

  notesForm = this.fb.group({
    description: ['', [Validators.minLength(10), Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    public validationMessages: ValidationMessagesService,
    private noteService: NoteService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.noteables.push(this.noteObject);
  }

  onSubmit() {
    if (!this.notesForm.valid) {
      return;
    }

    const params = JSON.parse(JSON.stringify(this.notesForm.value));
    params.noteables = this.noteables;

    this.noteService.add(params).subscribe(
      res => {
        this.toastr.success(this.translate.instant('notes.new_note_created'));
        this.notes.unshift(res.data);
        this.notesForm.reset();
      },
      err => {
        this.toastr.error(err.error.error.message || 'Error');
      },
    );
  }

  // not implemented yet, but with this you can link the note to other objects
  addNoteable(type: string, id: number) {
    this.noteables.push({ type, id });
  }
}
