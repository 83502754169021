import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthGuardService } from 'src/app/services/auth-guard.service';

@Directive({
  selector: '[appIfPermissions]',
})
export class IfPermissionsDirective {
  @Input() set appIfPermissions(permissions: string[]) {
    const requiredPermissions = permissions || [];
    if (this.authGuardService.isAllowed(requiredPermissions)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authGuardService: AuthGuardService) {}
}
