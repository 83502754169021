<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>{{label === 'trainee' ? 'files.attach_files_for_trainees' : 'files.attach_files_for_trainers'}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-file-upload [files]="uploadFiles"
                   (uploadFile)="addFile($event, label, fileArray)">
  </app-file-upload>
</div>
<div class="modal-footer">
  <button ngbAutofocus type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss()" translate>common.close</button>
</div>
