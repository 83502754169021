import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Helpers } from './services/helpers';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from './globals';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Training-Manager-Web';

  constructor(
    public translate: TranslateService,
    public globals: Globals,
    private router: Router,
    private helpers: Helpers,
    private titleService: Title,
    private metaService: Meta,
  ) {}

  ngOnInit() {
    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.helpers.changeOrganisationNameBreadcrumb();
      }
    });

    this.translate.onLangChange.subscribe(lang => {
      this.titleService.setTitle(this.translate.instant('meta-data.title'));
      this.metaService.addTag({ name: 'description', content: this.translate.instant('meta-data.description') });
    });
  }
}
