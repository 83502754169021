import { Injectable } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';

@Injectable()
export class Helpers {
  constructor(private breadcrumbService: BreadcrumbService) {
  }

  changeOrganisationNameBreadcrumb() {
    this.breadcrumbService.set(
      '@organisationName',
      JSON.parse(localStorage.getItem('session')) &&
        JSON.parse(localStorage.getItem('session')).user &&
      JSON.parse(localStorage.getItem('session')).user.logged_in_organisation
        ? JSON.parse(localStorage.getItem('session')).user.logged_in_organisation.name
        : '',
    );
  }
}
