<div id="wrapper">
  <app-sidebar (emitSidebarStatus)="sidebarOpen = $event"></app-sidebar>
  <div id="content" class="container-fluid">
    <div id="content-block" [class.active]="!sidebarOpen">
      <xng-breadcrumb separator=""></xng-breadcrumb>
      <app-container>
        <router-outlet></router-outlet>
      </app-container>
    </div>
    <div class="row text-muted pb-4 mb-3 footer" [class.active]="!sidebarOpen">
      <div class="col-12 text-center">
        <span>Competence Admin <a href="https://help.kiwasupport.com/otayhteytta" target="_blank">{{'common.contact_us' | translate}}</a></span>
      </div>
    </div>
  </div>
</div>
