<div class="modal-header border-0 pt-4 px-4 mx-2 pb-1">
  <h1 class="modal-title h4 w-100 text-center" id="modal-basic-title" translate>{{ title }}</h1>
  <div class="close" (click)="activeModal.dismiss()">
    <fa-icon [icon]="['fal', 'times']" [fixedWidth]="true"></fa-icon>
  </div>
</div>
<div class="modal-body border-0 px-4 mx-2">
  <div class="text-center">
    <div>
      <span *ngIf="text" class="custom-pre-wrap" translate>{{ text }}</span>
      <span *ngIf="item">:</span>
      <span *ngIf="data" class="font-weight-bolder">{{ data }}</span>
      <span class="py-3" *ngIf="additionalText">
        <span translate>{{ additionalText }}</span>
      </span>
      <form [formGroup]="organisationForm" (ngSubmit)="submitOrganisation()"*ngIf="organisationsForUser">
          <div class="form-group" >
            <ng-select [placeholder]="'filters.select_organisation' | translate" (input)="getOrganisations($event)" [clearable]="false" formControlName="organisation_id" id="organisation_id">
              <ng-option *ngFor="let organisation of organisationsForUser" [value]="organisation?.id">{{organisation.name}}</ng-option>
            </ng-select>
          </div>
      </form>
    </div>
    <div *ngIf="item">
      <span class="font-weight-bolder">{{ item }}</span>&nbsp;?
    </div>
  </div>
</div>

<div class="modal-footer justify-content-end border-0 px-4 mx-2 mb-4">
  <button *ngIf="organisationsForUser" class="btn btn-primary me-1 pointer" (click)="submitOrganisation()" translate>{{ buttonText }}</button>
  <div class="btn btn-outline-secondary pointer" (click)="activeModal.dismiss()" translate>common.cancel</div>
</div>
