<div class="row p-2">
    <div class="col px-0 border border-light me-3">
        <div class="row">
            <div class="col">
                <p class="font-weight-bold my-1 ms-2" translate> {{ headerLeft }}</p>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="input-group mb-3">
                    <input type="text" name="searchTerm" (input)="setVisible()" [(ngModel)]="searchTermIn" class="form-control" placeholder="{{'concepts.search_list' | translate}}" autofocus>
                    <fa-icon *ngIf="searchTermIn" (click)="emptyInConcept(); setVisible()" class="form-control-clear" [icon]="['fal', 'times']" [fixedWidth]="true"></fa-icon>
                    <button class="btn btn-primary rounded-end" (click)="setVisible()" type="submit"><fa-icon [icon]="['fal', 'search']"></fa-icon></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <ul class="list-group">
                    <li *ngFor="let item of listInVisible"
                        class="list-group-item d-flex justify-content-between align-items-center hover-button"
                        (click)="removeList(item)">
                        {{item.name}}
                        <span class="text-primary hidden-icon"><fa-icon [icon]="['fal', 'minus-square']" [fixedWidth]="true"></fa-icon></span>

                    </li>
                    <li *ngIf="listInVisible && listInVisible.length === 0" class="list-group-item d-flex justify-content-between align-items-center text-muted"
                    translate>common.nothing_found</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col px-0 border border-light ms-3">
        <div class="row">
            <div class="col">
                <p class="font-weight-bold my-1 ms-2" translate>{{ headerRight }}</p>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="input-group mb-3">
                    <input type="text" name="searchTerm" (input)="setVisible()" [(ngModel)]="searchTermOut" class="form-control" placeholder="{{'concepts.search_list' | translate}}" autofocus>
                    <fa-icon *ngIf="searchTermOut" (click)="emptyNotInConcept(); setVisible()" class="form-control-clear" [icon]="['fal', 'times']" [fixedWidth]="true"></fa-icon>
                    <button class="btn btn-primary rounded-end" (click)="setVisible()" type="submit"><fa-icon [icon]="['fal', 'search']"></fa-icon></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <ul class="list-group">
                    <li *ngFor="let item of listOutVisible"
                        class="list-group-item d-flex justify-content-between align-items-center hover-button"
                        (click)="addList(item)">
                        {{item.name}}
                        <span class="text-primary hidden-icon"><fa-icon [icon]="['fal', 'plus-square']" [fixedWidth]="true"></fa-icon></span>
                    </li>
                    <li *ngIf="listOutVisible && listOutVisible.length === 0" class="list-group-item d-flex justify-content-between align-items-center text-muted"
                        translate>common.nothing_found</li>
                </ul>
            </div>
        </div>
    </div>
</div>
