<div class="modal-header border-0 pt-4 px-4 mx-2 pb-1">
  <h1 class="modal-title h4 w-100 text-center" id="modal-basic-title" translate>common.excel_import</h1>
  <div class="close pointer" (click)="activeModal.dismiss()">
    <fa-icon [icon]="['fal', 'times']" [fixedWidth]="true"></fa-icon>
  </div>


</div>

<div class="modal-body border-0 px-4 mx-2 mb-4">
  <div class="row justify-content-center">
    <div class="col-12 mb-3">
      <label for="inputExcel" class="btn btn btn-primary mb-0">
        <input id="inputExcel"
               type="file"
               hidden
               (change)="excelImport($event.target)"
               multiple="false"
               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
        <span translate>common.excel_import</span>
      </label>
    </div>
  </div>

  <div>
    <div><strong translate>excelImport.help_title</strong></div>
    <div [innerHTML]="'excelImport.help' | translate"></div>
  </div>

  <div class="d-block mt-4" style="overflow-x: scroll" *ngIf="showPreview">
    <table class="table">
      <thead>
      <tr>
        <th *ngFor="let col of columns; let colIndex = index">
          <div class="form-group">
          <select class="form-control" style="width:auto;" [(ngModel)]="columnOrder[colIndex]" (change)="mapData()">
            <option translate value="">excelImport.skip</option>
            <option *ngFor="let option of columns; let optionIndex = index" [value]="optionIndex" translate>excelImport.fields.{{ option }}</option>
          </select>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let row of excelData; let rowIndex = index">
        <td *ngFor="let col of row; let colIndex = index">
          {{ col }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="d-block my-4" style="overflow-x: scroll" *ngIf="showPreview">
    <div class="h4 w-100 text-center mb-3" translate>excelImport.preview</div>

    <table class="table">
      <thead>
      <tr>
        <th *ngFor="let col of columns; let colIndex = index">
          <div class="form-group">
            <span translate>excelImport.fields.{{ col }}</span>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let row of mappedData">
        <td *ngFor="let col of columns">
          {{ row[col] }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</div>

<div class="modal-footer justify-content-between border-0 px-4 mx-2 mb-4">
  <div>
    <a class="btn btn-outline-primary" href="assets/participant-sample-excel.xlsx" type="file" download>
      <div translate>excelImport.download_sample</div>
    </a>
  </div>
  <div>
    <button class="btn btn-primary me-1 pointer" [disabled]="!showPreview" (click)="importParticipants()" translate>excelImport.import</button>
    <div class="btn btn-outline-secondary pointer" (click)="activeModal.dismiss()" translate>common.cancel</div>
  </div>
</div>
