<ngx-file-drop [showBrowseBtn]="true"
               (onFileDrop)="addFiles($event)"
               contentClassName="drop-zone-content-custom"
               dropZoneClassName="drop-zone-custom">
  <ng-template let-openFileSelector="openFileSelector" ngx-file-drop-content-tmp>
    <div class="ngx-file-drop__drop-zone-label-custom" translate>
      files.drop_files_here
    </div>
    <div translate>
      files.or
    </div>
    <div>
      <button (click)="openFileSelector()" class="btn btn-outline-secondary" translate type="button">files.browse</button>
    </div>
  </ng-template>
</ngx-file-drop>
<div class="my-4">
  <div *ngFor="let file of files" class="row">
    <div class="col-5 text-truncate">
      <fa-icon [fixedWidth]="true" [icon]="['fal', 'file']" class="me-1"></fa-icon>
      <span>{{file.file.fileEntry.name}}</span>
    </div>
    <div class="col-7">
      <ngb-progressbar [value]="file.progress"></ngb-progressbar>
    </div>
  </div>
</div>
