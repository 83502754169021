import { Component, Injectable, Input, NgModule, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TermsService } from '../../services/terms.service';
import { Terms } from '../../models/terms.model';
import { TermLanguage } from '../../models/term-language.model';

@Component({
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.scss'],
})
export class TermsDialogContentComponent implements OnInit {
  @Input() accept_terms = [];
  acceptedTerms = [];
  currentTerms: Terms;
  termLanguage: TermLanguage;

  constructor(public activeModal: NgbActiveModal, private termsService: TermsService) {}

  ngOnInit() {
    this.checkNextTerms();
  }

  // Accept current terms and get next ones
  acceptCurrentTerms() {
    this.termsService.acceptTerms(this.currentTerms.id, this.termLanguage.id).subscribe(
      () => {
        this.acceptedTerms.push(this.currentTerms.id);
        this.checkNextTerms();
      },
      () => {
        this.activeModal.dismiss();
      },
    );
  }

  checkNextTerms() {
    // Get terms that needs to be accepted next
    const nextTermsToAccept = this.getNextTermsToAccept();
    // If we have terms to accept get the next terms and show them
    if (nextTermsToAccept) {
      this.getNextTerms(nextTermsToAccept).then(terms => {
        this.currentTerms = terms.data[0];
        this.termLanguage = this.currentTerms.terms_languages[0];
      });
    } else {
      // Close modal because we dont have any more terms to accept
      this.activeModal.close();
    }
  }

  getTermsText() {
    if (!this.currentTerms || !this.currentTerms.terms_languages) {
      return;
    }
    for (const language of this.currentTerms.terms_languages) {
      if (language.language.code === this.termLanguage.language.code) {
        return language.text;
      }
    }
  }

  getNextTermsToAccept() {
    for (const termsToAccept of this.accept_terms) {
      if (!this.acceptedTerms.includes(termsToAccept)) {
        return termsToAccept;
      }
    }
    // If we don't have terms to accept anymore return null
    return null;
  }

  getNextTerms(id) {
    return this.termsService.getTerms({ 'id[]': id }).toPromise();
  }
}

@Injectable({
  providedIn: 'root',
})
export class TermsDialogComponent {
  constructor(private modalService: NgbModal) {}

  confirm(options: Options) {
    const ref = this.modalService.open(TermsDialogContentComponent, { scrollable: true });
    ref.componentInstance.accept_terms = options.accept_terms;
    ref.result.then(() => options.accept(), () => options.reject && options.reject()).catch(() => options.reject && options.reject());
  }
}

export interface Options {
  accept: Function;
  accept_terms: number[];
  reject?: Function;
}

@NgModule({
  declarations: [TermsDialogContentComponent],
  imports: [TranslateModule, CommonModule, FontAwesomeModule],
})
export class TermsDialogModule {}
