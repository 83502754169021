<div class="d-flex justify-content-between mb-3">
    <h3 *ngIf="title" translate>{{ title }}</h3>
    <div *ngIf="titleButtons && titleButtons.length > 0">
        <div *ngFor="let button of titleButtons">
            <div *ngIf="button.admin; else ifPermission">
                <a *ngIf="isSuperAdmin()" [routerLink]="[button.route]" class="btn btn-primary ml-auto" translate>{{ button.buttonName }}</a>
            </div>
            <ng-template #ifPermission>
                <a *appIfPermissions="button.permissions" [routerLink]="[button.route]" class="btn btn-primary ml-auto" translate>{{ button.buttonName }}</a>
            </ng-template>
        </div>
    </div>
</div>
