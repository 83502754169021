<div class="input-group">
  <input
    class="form-control custom-input"
    placeholder="{{ placeholder | translate }}"
    [value]="formattedDateRange"
    ngbDatepicker
    #dp="ngbDatepicker"
    (dateSelect)="onDateSelection($event)"
    [container]="'body'"
    [displayMonths]="2"
    [dayTemplate]="t"
    [autoClose]="'outside'"
    (click)="dp.toggle()"
    (closed)="onClose()"
    (change)="onInputChanged($event)"
  />
  <button type="button" *ngIf="from" class="clear-button rounder-start" (click)="onClear()">
    <fa-icon [fixedWidth]="true" [icon]="['fal', 'times']"></fa-icon>
  </button>
  <button type="button" class="btn btn-outline-secondary calendar-button rounded-end" (click)="dp.toggle()">
    <fa-icon [fixedWidth]="true" [icon]="['fal', 'calendar-alt']"></fa-icon>
  </button>
</div>

<ng-template #t let-date="date" let-focused="focused">
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = toDate(date)"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>
