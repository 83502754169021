import {
  Directive,
  Input,
  HostBinding,
  Optional,
  Host,
  SkipSelf, ElementRef,
} from '@angular/core';
import { ControlContainer, FormControl } from "@angular/forms";
import { ValidationErrorService } from '../services/validationError.service';

export function controlPath(name: string, parent: ControlContainer): string[] {
  return [...parent.path!, name];
}

@Directive({
  selector: 'ng-select'
})
export class NgSelectDirective {
  @Input() formControlName: string;
  @Input() formControl: string;
  @Input() id: string;

  @HostBinding('class.is-valid')
  get validClass() {
    if (!this.control) {
      return false;
    }
    if (!this.id) {
      console.error('Form control: ' + this.formControlName + ' does not have id value set!');
    }
    if (this.control.valid && (this.control.touched || this.control.dirty)) {
      const el = document.getElementById(this.id);
      if (el) {
        for (const childEl of Array.prototype.slice.call(el.parentElement.children)) {
          if (childEl.classList.contains('ccm-validation-node')) {
            el.parentElement.removeChild(childEl);
          }
        }
      }
    }
    return (
      this.control.valid &&
      (this.control.touched || this.control.dirty)
    );
  }

  @HostBinding('class.is-invalid')
  get invalidClass() {
    if (!this.control) {
      return false;
    }
    if (!this.id) {
      console.error('Form control: ' + this.formControlName + ' does not have id value set!');
    }
    if (this.control.invalid && (this.control.touched || this.control.dirty)) {
      const el = document.getElementById(this.id);
      const node = document.createElement('small');
      if (el) {
        for (const childEl of Array.prototype.slice.call(el.parentElement.children)) {
          if (childEl.classList.contains('ccm-validation-node')) {
            el.parentElement.removeChild(childEl);
          }
        }
      }
      const firstError = Object.keys(this.control.errors)[0];
        const error = this.validationErrorService.getCustomErrorMessages().find(e => e.error === firstError);
      if (firstError === 'maxlength') {
        node.textContent = error.format(firstError, {requiredLength: this.control.errors[firstError].requiredLength});
      } else {
        node.textContent = error.format();
      }
      node.classList.add('ccm-validation-node');
      node.classList.add('text-danger');
      if (el.parentElement) {
        el.parentElement.appendChild(node);
      }
    }
    return (
      this.control.invalid &&
      this.control.touched &&
      this.control.dirty
    );
  }

  get path() {
    return controlPath(this.formControlName, this.parent);
  }

  get control(): FormControl {
    return this.formDirective && this.formDirective.getControl(this);
  }

  get formDirective(): any {
    return this.parent ? this.parent.formDirective : null;
  }

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private parent: ControlContainer,
    private element: ElementRef,
    private validationErrorService: ValidationErrorService
  ) {}
}
