import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-change-lists',
  templateUrl: './change-lists.component.html',
  styleUrls: ['./change-lists.component.scss'],
})
export class ChangeListsComponent implements OnChanges {
  @Input() listIn = [];
  @Input() listOut = [];
  @Input() headerLeft;
  @Input() headerRight;

  @Output() remove = new EventEmitter<any>();
  @Output() add = new EventEmitter<any>();
  listInVisible;
  listOutVisible;
  searchTermOut = '';
  searchTermIn = '';
  constructor() {}

  ngOnChanges() {
    this.setVisible();
  }

  emptyNotInConcept() {
    this.searchTermOut = '';
  }

  emptyInConcept() {
    this.searchTermIn = '';
  }

  setVisible() {
    this.listInVisible = this.listIn.filter(value => {
      return value.name.toLowerCase().includes(this.searchTermIn.toLowerCase());
    });
    this.listOutVisible = this.listOut.filter(value => {
      return value.name.toLowerCase().includes(this.searchTermOut.toLowerCase());
    });
  }

  removeList(item) {
    this.remove.emit(item);
  }

  addList(item) {
    this.add.emit(item);
  }
}
