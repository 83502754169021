import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Organisation, OrganisationFile, OrganisationParams } from '../models/organisation.model';
import {Observable} from "rxjs";

export interface OrganisationApiResponse {
  status: string;
  success: boolean;
  data: Organisation;
}

export interface OrganisationsApiResponse {
  status: string;
  success: boolean;
  data: Organisation[];
  pagination: any;
}

@Injectable({
  providedIn: 'root',
})
export class OrganisationService {
  url = `${environment.apiUrl}v1/organisations`;

  constructor(private http: HttpClient) {}

  updateTags(orgaId: number, tagIds: number[]): Observable<any> {
    return this.http.post(this.url + '/' + orgaId + '/tags', {
      tags: tagIds
    });
  }

  getOrganisationCustomers(organisationId, params?: {}) {
    return this.http.get(this.url + '/' + organisationId + '/customers', { params });
  }

  updateOrganisation(id: number, payload: OrganisationParams, params: {}) {
    return this.http.put<OrganisationApiResponse>(`${this.url}/${id}`, payload, { params: params });
  }

  getInvitedUsers(organisationId, params?: {}) {
    return this.http.get(this.url + '/' + organisationId + '/invitedUsers', { params: params });
  }

  addOrganisation(organisation: OrganisationParams) {
    return this.http.post<OrganisationApiResponse>(this.url, organisation);
  }

  getAll(params: {}) {
    return this.http.get<OrganisationsApiResponse>(this.url, { params: params });
  }

  getById(id: number, params: {}) {
    return this.http.get<OrganisationApiResponse>(`${this.url}/${id}`, { params: params });
  }

  deactivate(id) {
    return this.http.delete(`${this.url}/${id}`);
  }

  setOrganisationLogo(organisationId, payload: any, params: {}) {
    return this.http.post<any>(`${environment.apiUrl}v1/organisations/${organisationId}/logo`, payload, { params: params });
  }

  deleteOrganisationLogo(organisationId, params) {
    return this.http.delete<OrganisationApiResponse>(`${environment.apiUrl}v1/organisations/${organisationId}/logo`, { params: params });
  }

  getOrganisationCompanies(params: {}) {
    return this.http.get<OrganisationsApiResponse>(environment.apiUrl + 'v1/organisationcompanies', { params: params });
  }
}
