import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'participantStatus',
  pure: false,
})
export class ParticipantStatusPipe implements PipeTransform {
  transform(participants: any[], ...args: any[]): any {
    return participants.filter(participant => {
      // if noForm, then viewing locked event without a form
      if (args.indexOf('noForm') > -1) {
        return args.indexOf(participant.status) > -1;
      } else {
        return args.indexOf(participant.value.status) > -1;
      }
    });
  }
}
