import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from 'src/app/services/auth-guard.service';

import { LayoutComponent } from './layout.component';

const routes: Routes = [
  {
    canActivate: [AuthGuardService],
    path: '',
    component: LayoutComponent,
    data: { permissions: [] },
    children: [
      { path: 'home', loadChildren: () => import('../home/home.module').then(m => m.HomeModule) },
      { path: 'cards', loadChildren: () => import('../cards/cards.module').then(m => m.CardsModule) },
      { path: 'trainings', loadChildren: () => import('../trainings/trainings.module').then(m => m.TrainingsModule) },
      { path: 'organisations', loadChildren: () => import('../organisations/organisations.module').then(m => m.OrganisationsModule) },
      { path: 'trainings', loadChildren: () => import('../trainings/trainings.module').then(m => m.TrainingsModule) },
      { path: 'users', loadChildren: () => import('../users/users.module').then(m => m.UsersModule) },
      { path: 'superadmins', loadChildren: () => import('../super-admins/admins.module').then(m => m.AdminsModule) },
      { path: 'trainingtemplates', loadChildren: () => import('../training-templates/training-templates.module').then(m => m.TrainingTemplatesModule) },
      { path: 'locations', loadChildren: () => import('../locations/locations.module').then(m => m.LocationsModule) },
      { path: 'events', loadChildren: () => import('../events/events.module').then(m => m.EventsModule) },
      { path: 'trainingcodes', loadChildren: () => import('../training-codes/training-codes.module').then(m => m.TrainingCodesModule) },
      { path: 'statistics', loadChildren: () => import('../training-counts/training-counts.module').then(m => m.TrainingCountsModule)  },
      { path: 'profile', loadChildren: () => import('../profile/profile.module').then(m => m.ProfileModule) },
      { path: 'materials', loadChildren: () => import('../materials/materials.module').then(m => m.MaterialsModule) },
      { path: 'concepts', loadChildren: () => import('../concepts/concepts.module').then(m => m.ConceptsModule) },
      { path: 'companies', loadChildren: () => import('../companies/companies.module').then(m => m.CompaniesModule) },
      { path: '**', redirectTo: '/home' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
