<div class="modal-header border-0 pt-4 px-4">
  <h1 class="modal-title h4 w-100 text-center" id="modal-basic-title" translate>terms.terms_of_service</h1>
  <button type="button" class="close" attr.aria-label="{{ 'common.decline' | translate }}" (click)="activeModal.dismiss()">
    <fa-icon [icon]="['fal', 'times']" [fixedWidth]="true"></fa-icon>
  </button>
</div>
<div class="modal-body border-0">
  <div>
    <span (click)="termLanguage = language" style="font-size: 1.2em" *ngFor="let language of currentTerms?.terms_languages; last as isLast">
      <span class="pointer" [class.text-primary]="termLanguage === language">{{language.language.code | uppercase}}</span><span *ngIf="!isLast"> | </span>
    </span>
  </div>
  <p class="custom-pre-wrap">{{getTermsText()}}</p>
</div>
<div class="modal-footer justify-content-center border-0 pb-4">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()" translate>common.decline</button>
  <button type="button" class="btn btn-primary" (click)="acceptCurrentTerms()" translate>common.accept</button>
</div>
