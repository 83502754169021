import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-component',
  templateUrl: './layout-component.component.html',
  styleUrls: ['./layout-component.component.scss'],
})
export class LayoutComponentComponent implements OnInit {
  // REPLACE LAYOUTCOMPONENT TO <app-page-columns>

  // label of the row
  @Input() label?: string;
  // value of the singular row
  @Input() value?: string;
  // if we need extra style classes to the singular value
  @Input() extraStyle?: string;
  //  List of values for multiple inserts
  @Input() valueArray?: string[];
  //  List of labels for multiple inserts
  @Input() labelArray?: string[];
  //  On multiple inserts if we want to use header
  @Input() header?: string;
  // If the row needs to loop through a list
  @Input() loopValueArray?: any[];
  // Name that we need to use on a loop through
  @Input() loopName?: string;
  // If we want to translate value in loopValueArray
  @Input() loopTranslatePrefix?: string;

  constructor() {}

  ngOnInit() {}
}
