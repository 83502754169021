import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcelImportService {
  constructor() {}

  handleExcel(files: any) {
    return new Promise((resolve, reject) => {
      if (files.files && files.files.length > 0) {
        const target: DataTransfer = <DataTransfer>files;
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

          const wsname: string = wb.SheetNames[0];
          const es: XLSX.WorkSheet = wb.Sheets[wsname];
          const parsedExcel = XLSX.utils.sheet_to_json(es, { header: 1 });
          const data = [];
          for (let i = 1; i < parsedExcel.length; i++) {
            // Ignore the first row if the first column is 'first name'
            // if (
            //   (<any>parsedExcel[i]).length > 0 &&
            //   !(i === 0 && (parsedExcel[i][0].toLowerCase() === 'first name' || parsedExcel[i][0].toLowerCase() === 'etunimi'))
            // ) {
            //   data.push(parsedExcel[i]);
            // }

            // always ignore the first line
            if ((<any>parsedExcel[i]).length > 1) {
              data.push(parsedExcel[i]);
            }
          }
          resolve(data);
        };
        reader.readAsBinaryString(target.files[0]);
      }
    });
  }

  validateExcel(files: any) {
    return (
      files.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      files.files[0].type === 'application/vnd.ms-excel'
    );
  }
}
