<nav id="sidebar" class="nav-container" [class.active]="!menuOpen">
  <div class="nav-header-flex my-1">
    <div class="d-flex justify-content-between px-3">
      <div *ngIf="menuOpen" class="mx-auto text-center">
        <img class="hide-mobile img-fluid rounded mt-2 organisation-logo" [src]="getOrganisationLogo()">

        <p class="mt-2 mb-0" (click)="changeOrganisation()" >{{ authGuardService.getSessionData()?.user?.logged_in_organisation?.name }}
          <fa-icon class="me-1" [icon]="['fal', 'edit']"></fa-icon>
        </p>
      </div>
      <fa-icon class=" pointer" [ngClass]="menuOpen ? 'pe-3 mt-3' : 'mx-auto pt-3'" [icon]="['fal', 'bars']" size="lg" (click)="toggleMenu()"></fa-icon>
    </div>
    <hr>
    <div class="sidenav-profile d-flex my-2 align-items-center" [ngClass]="{'justify-content-around': !menuOpen}" [routerLink]="organisationId ? '/profile': null">
      <img style="width: 50px; height: 50px;" class="img-fluid rounded-circle pointer" src="../../../assets/avatar.png">
      <div class="hide-mobile pointer text-left">
        <p class="ms-2 mt-1 mb-0">{{ user?.firstname }}</p>
        <p class="ms-2 mt-1 mb-0">{{ user?.lastname }}</p>
      </div>
    </div>
    <hr>
  </div>

  <div class="nav-body-flex" [class.mx-auto]="!menuOpen">
    <ul class="nav flex-column flex-nowrap">
      <li class="nav-item" [ngbTooltip]="!menuOpen ? translate.instant(navItem.text) : ''" *ngFor="let navItem of visibleNavItems">
        <a *ngIf="!navItem.customItem; else customItem" class="d-block my-2" [routerLink]=[navItem.url]>
          <fa-icon [icon]="['fal', navItem.icon]" [fixedWidth]="true"></fa-icon>
          <span translate class="hide-mobile">{{navItem.text}}</span>
        </a>
        <ng-template #customItem>
          <a *ngIf="navItem.text === 'sidebar.help'" class="d-block my-2 pointer sidenav-link" (click)="navItem.text === 'sidebar.help' ? openInNewTab(helpLinkUrl) : openInNewTab(navItem.url)">
            <fa-icon [icon]="['fal', navItem.icon]" [fixedWidth]="true"></fa-icon>
            <span translate class="hide-mobile">{{navItem.text}}</span>
          </a>
          <a *ngIf="navItem.text === 'sidebar.materials' && showMaterials()" class="d-block my-2 pointer sidenav-link" [routerLink]="[navItem.url]">
            <fa-icon [icon]="['fal', navItem.icon]" [fixedWidth]="true"></fa-icon>
            <span translate class="hide-mobile">{{navItem.text}}</span>
          </a>
        </ng-template>
      </li>
      <li class="nav-item" [ngbTooltip]="!menuOpen ? translate.instant('sidebar.login') : ''" *ngIf="!auth.isAuthenticated()">
        <a class="d-block my-2" [routerLink]="'/login'">
          <fa-icon [icon]="['fal', 'lock']" [fixedWidth]="true"></fa-icon>
          <span class="hide-mobile">{{'sidebar.login' | translate}}</span>
        </a>
      </li>
      <li class="nav-item" [ngbTooltip]="!menuOpen ? translate.instant('sidebar.logout') : ''" *ngIf="auth.isAuthenticated()">
        <a class="d-block my-2 pointer sidenav-link" (click)="logout()">
          <fa-icon [icon]="['fal', 'times-circle']" [fixedWidth]="true"></fa-icon>
          <span class="hide-mobile">{{'sidebar.logout' | translate}}</span>
        </a>
      </li>
    </ul>
  </div>

  <div class="nav-footer-flex">
    <div class=" pb-4 pt-4">
      <div *ngIf="selectingLanguage">
        <div class="list-unstyled d-flex align-items-center justify-content-center flex-column">
          <ul class="list-unstyled">
            <li class="" *ngFor="let lang of globals.languages">
              <button (click)="changeLanguage(lang.short_name)" class="btn"><img class="pointer" style="margin-top: -2px" [src]="'../../assets/' + lang.short_name + '.svg'" [alt]="lang.name"> <span class="hide-mobile ps-2">{{'sidebar.languages.' + lang.short_name | translate}}</span></button>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="!selectingLanguage" class="text-center">
        <button (click)="selectingLanguage = true;" class="btn ps-2"><img style="margin-top: -2px" [src]="'../../assets/' + this.translate.currentLang + '.svg'" [alt]="translate.currentLang"><span class="ms-2 hide-mobile">{{'sidebar.languages.' + this.translate.currentLang | translate}}</span> <span class="ms-1 hide-mobile" style="font-size: 12px">▼</span></button>
      </div>
    </div>
  </div>

</nav>
