import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient
  ) { }

  getToken(): string {
    return localStorage.getItem('ng2-ui-auth_accessToken');
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem('ng2-ui-auth_accessToken');
  }

  login(user: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'v1/auth/login', user);
  }

  logout(): Observable<any> {
    return this.http.get(environment.apiUrl + 'v1/logout');
  }

}
