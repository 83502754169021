<div *ngIf="user">
  <app-layout-component [header]="'users.user_details'" [valueArray]="[
  user.firstname,
  user.lastname
  ]" [labelArray]="[
  'users.firstname',
  'users.lastname'
  ]"></app-layout-component>
  <app-layout-component [value]="user.email" [label]="'users.email'" [extraStyle]="'email-length'">
  </app-layout-component>
</div>

