import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Note } from '../models/note.model';

export interface NoteApiResponse {
  status: string;
  success: boolean;
  data: Note;
}

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  url = environment.apiUrl + 'v1/notes';

  constructor(private http: HttpClient) {}

  add(params: any) {
    return this.http.post<NoteApiResponse>(this.url, params);
  }

  update(id, params?: {}) {
    return this.http.put<NoteApiResponse>(this.url + '/' + id, params);
  }

  delete(id) {
    return this.http.get<NoteApiResponse>(this.url + '/' + id);
  }
}
