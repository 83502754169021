import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import {UserService} from "./user.service";

export interface SessionData {
  user: any;
  permissions: string[];
  superadmin: boolean;
  show_materials?: boolean;
  roles?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService  {
  private sessionDataKey = 'session';
  private sessionData: SessionData;

  constructor(private auth: AuthService, private router: Router, private userService: UserService) {
    this.refreshSessionData();
  }

  private refreshSessionData() {
    this.sessionData = JSON.parse(localStorage.getItem(this.sessionDataKey)) || {};
  }

  hasRole(role: 'trainer' | 'secretary' | 'training_center_admin' | 'training_concept_admin'): boolean {
    if (!this.sessionData?.roles) {
      this.userService.getUser(this.userService.getCurrentUserId(), {params: {with: 'roles'}}).subscribe((res: any) => {
        this.sessionData['roles'] = res.data.roles;
        return this.sessionData.roles.some(r => r === role);
      }, (err) => {
        this.sessionData['roles'] = [];
      });
    } else {
      return this.sessionData.roles.some(r => r === role);
    }
    return false;
  }

  setSessionData(data: SessionData) {
    localStorage.setItem(this.sessionDataKey, JSON.stringify(data));
    this.refreshSessionData();
  }

  getSessionData(): SessionData {
    return JSON.parse(localStorage.getItem(this.sessionDataKey));
  }

  clearSessionData() {
    localStorage.removeItem(this.sessionDataKey);
    this.refreshSessionData();
  }

  isSuperAdmin() {
    return this.auth.isAuthenticated() && !!this.sessionData.superadmin;
  }

  getOrganisationId() {
    return this.sessionData.user ? this.sessionData.user.logged_in_organisation.id : undefined;
  }

  isAllowed(requiredPermissions: string[] = []) {
    if (this.isSuperAdmin()) {
      return true;
    }

    if (this.auth.isAuthenticated()) {
      const userPermissions = this.sessionData.permissions || [];
      if (requiredPermissions.length === 0 || requiredPermissions.some(permission => userPermissions.includes(permission))) {
        return true;
      }
    }
    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const requireSuperAdmin = !!route.data['superadmins'];

    if (requireSuperAdmin && this.isSuperAdmin()) {
      return true;
    }

    const requiredPermissions = route.data['permissions'] || [];
    if (!requireSuperAdmin && this.isAllowed(requiredPermissions)) {
      return true;
    }

    const urlTree = this.auth.isAuthenticated()
      ? this.router.createUrlTree(['/'])
      : this.router.createUrlTree(['/login']);

    this.router.navigateByUrl(urlTree);

    return false;
  }
}
