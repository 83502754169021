import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-columns',
  templateUrl: './page-columns.component.html',
  styleUrls: ['./page-columns.component.scss'],
})
export class PageColumnsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
