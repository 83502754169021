import { Component, EventEmitter, Input, NgModule, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PasswordValidation } from '../../reset-password/reset-password.component';

@Component({
  selector: 'app-password-validation',
  templateUrl: './password-validation.component.html',
  styleUrls: ['./password-validation.component.scss'],
})
export class PasswordValidationComponent implements OnChanges {
  @Input() passwordInput: string;
  @Output() validationResult = new EventEmitter();
  @Input() passwordRepeatInput: string;

  LENGTH = 'length';
  NUMBER = 'number';
  CAPITAL = 'capital';
  SMALL = 'small';
  PASSWORDS = 'passwords';
  SYMBOL = 'symbol';

  validationsCompleted = false;

  validations: Array<PasswordValidation> = [
    {
      text: 'reset-password.validation_characters',
      completed: false,
      tag: this.LENGTH,
    },
    {
      text: 'reset-password.validation_number',
      completed: false,
      tag: this.NUMBER,
    },
    {
      text: 'reset-password.validation_capital',
      completed: false,
      tag: this.CAPITAL,
    },
    {
      text: 'reset-password.validation_small',
      completed: false,
      tag: this.SMALL,
    },
    {
      text: 'reset-password.validation_passwords_match',
      completed: false,
      tag: this.PASSWORDS,
    },
    {
      text: 'reset-password.validation_symbol',
      completed: false,
      tag: this.SYMBOL,
    },
  ];

  constructor(private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.validationChange(this.passwordInput, this.passwordRepeatInput);
  }

  validationChange(password: string, passwordRepeat: string) {
    this.validationsCompleted = true;
    for (const val of this.validations) {
      switch (val.tag) {
        case this.SMALL:
          val.completed = password.toUpperCase() !== password;
          break;
        case this.CAPITAL:
          val.completed = password.toLowerCase() !== password;
          break;
        case this.LENGTH:
          val.completed = password.length >= 8;
          break;
        case this.NUMBER:
          val.completed = /\d/.test(password);
          break;
        case this.PASSWORDS:
          val.completed = password !== '' && passwordRepeat !== '' && password === passwordRepeat;
          break;
        case this.SYMBOL:
          val.completed = /[!@#$%^&*()\-_=+,.\/?|`~[\]{};:]/.test(password);
          break;
      }
      if (!val.completed) {
        this.validationsCompleted = false;
      }
    }
    this.validationResult.emit(this.validationsCompleted);
  }
}

@NgModule({
  declarations: [PasswordValidationComponent],
  imports: [TranslateModule, CommonModule, FontAwesomeModule],
  exports: [PasswordValidationComponent],
})
export class PasswordValidationModule {}
