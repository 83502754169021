import { Component, Input, OnChanges } from '@angular/core';
import { TrainingTemplate } from 'src/app/models/training-template.model';
import { TrainingTemplateService } from 'src/app/services/training-template.service';
import * as moment from 'moment';
import { TrainingEvent } from '../../models/training-event.model';
import { FileAttachment } from '../../models/file-attachment.model';
import { FilehandlerService } from '../../services/filehandler.service';

@Component({
  selector: 'app-template-details',
  templateUrl: './template-details.component.html',
  styleUrls: ['./template-details.component.scss'],
})
export class TemplateDetailsComponent implements OnChanges {
  @Input() trainingTemplate: TrainingTemplate;
  @Input() selectedEvent: TrainingEvent;
  @Input() isFileLoading?: boolean;
  @Input() hideFiles?: boolean;

  trainer_requirements = [];
  trainee_requirements = [];
  trainerFiles: FileAttachment[] = [];
  traineeFiles: FileAttachment[] = [];

  constructor(public trainingTemplateService: TrainingTemplateService, public filehandlerService: FilehandlerService) {}

  ngOnChanges() {
    if (this.trainingTemplate && this.trainingTemplate.requirements) {
      this.trainee_requirements = this.trainingTemplate.requirements.filter(req => req.requirement_role === 'trainee');
      this.trainer_requirements = this.trainingTemplate.requirements.filter(req => req.requirement_role === 'trainer');
    }
    if (this.trainingTemplate && this.trainingTemplate.trainee_attachments && this.trainingTemplate.trainer_attachments) {
      this.trainerFiles = this.trainingTemplate.trainer_attachments;
      this.traineeFiles = this.trainingTemplate.trainee_attachments;
    }
  }

  getLastSubmitDate() {
    if (!this.selectedEvent) {
      return;
    }
    return moment(this.selectedEvent.starting_at)
      .subtract(this.trainingTemplate.days_to_submit, 'day')
      .format('DD.MM.YYYY');
  }

  getLastParticipantsSubmitDate() {
    if (!this.selectedEvent) {
      return;
    }
    return moment(this.selectedEvent.ending_at)
      .add(this.trainingTemplate.days_to_assign_participants, 'day')
      .format('DD.MM.YYYY');
  }
}
