import { Component, NgModule, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { PermissionsModule } from '../permissions/permissions.module';

@Component({
  selector: 'app-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent implements OnInit {
  constructor(private route: Router) {}

  ngOnInit() {}

  isHomePage() {
    return this.route.url === '/home' || this.route.url === '/' ;
  }
}

@NgModule({
  declarations: [PageContainerComponent],
  imports: [TranslateModule, CommonModule, RouterModule, PermissionsModule],
  exports: [PageContainerComponent],
})
export class PageContainerModule {}
