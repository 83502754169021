import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FileAttachmentService } from '../../services/file-attachment.service';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { ToastrService } from 'ngx-toastr';
import { FileAttachment } from '../../models/file-attachment.model';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
})
export class FileListComponent implements OnInit {
  @Input() fileArray: Array<FileAttachment>;

  constructor(public fileAttachmentService: FileAttachmentService, private toastr: ToastrService, private translate: TranslateService) {}

  ngOnInit() {}
  removeFile(file, index: number) {
    if (file.pivot.label !== 'not_attached') {
      this.fileAttachmentService.removeFile(file.pivot.id).subscribe(
        res => {
          this.fileArray.splice(index, 1);
          this.toastr.success(this.translate.instant('files.removed'));
        },
        () => {
          this.toastr.error(this.translate.instant('files.removed_error'));
        },
      );
    } else {
      this.fileArray.splice(index, 1);
      this.toastr.success(this.translate.instant('files.removed'));
    }
  }
}

@NgModule({
  declarations: [FileListComponent],
  imports: [
    TranslateModule,
    CommonModule,
    FontAwesomeModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'success', // set defaults here
    }),
  ],
  exports: [FileListComponent, ConfirmationPopoverModule],
})
export class FileListModule {}
