import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuardService } from 'src/app/services/auth-guard.service';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'resetPassword/:token', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  {
    canActivate: [AuthGuardService],
    path: '',
    pathMatch: 'full',
    component: LayoutComponent,
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    data: {
      permissions: [],
      breadcrumb: {alias: 'organisationName'},
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
