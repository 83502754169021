import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateRangeFilter, Filter, FilterSize, FilterType, MultiSelectAddNewFilter, MultiSelectFilter, SingleSelectFilter } from './filters';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  selected: Filter[] = [];
  filterSize = FilterSize;
  @Output() change = new EventEmitter<Filter[]>();
  oldFilters: Filter[];

  constructor(private translate: TranslateService) {}

  _filters: Filter[] = [];

  @Input() set filters(filters: Filter[]) {
    this.oldFilters = filters;
    this._filters = filters.map(filter => {

      if (filter.sortBy && filter['items']) {
        filter['items'].sort((a,b)=> (a['label'].toLowerCase() > b['label'].toLowerCase() ? 1 : -1));
      }

      if (this.isMultiSelect(filter)) {
        const f = filter as MultiSelectFilter;
        return {
          ...f,
          items: f.items
            ? f.items.map(item => ({ ...item, label: f.translateItemLabels ? this.translate.instant(item.label) : item.label }))
            : [],
        };
      }
      return filter;
    });

    this.selected = [...this._filters];
  }

  get visibleFilters(): Filter[] | any[] {
    return this._filters.filter(filter => !filter.hidden);
  }

  get activeFiltersCount(): number {
    return this.selected
      .filter(filter => !filter.hidden)
      .filter(filter => {
        if (this.isMultiSelect(filter)) {
          const f = filter as MultiSelectFilter;
          return f.selected && f.selected.length > 0;
        } else if (this.isDateRange(filter)) {
          const f = filter as DateRangeFilter;
          return f.from;
        } else if (this.isMultiSelectAddNew(filter)) {
          const f = filter as MultiSelectAddNewFilter;
          return f.selected && f.selected.length > 0;
        } else if (this.isTextBox(filter)) {
          const f = filter as MultiSelectAddNewFilter;
          return f.selected && f.selected.length > 0;
        } else if (this.isSingleSelect(filter)) {
          const f = filter as SingleSelectFilter;
          return f.selected && f.selected.length > 0;
        }
      }).length;
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe(() => {
      this._filters = this._filters.map(filter => {
        if (this.isMultiSelect(filter)) {
          const f = filter as MultiSelectFilter;
          const oldFilter = this.oldFilters.find(value => value.id === f.id) as MultiSelectFilter;
          return {
            ...f,
            selected: this.selected.find(value => value.id === f.id).selected,
            items: oldFilter.items
              ? oldFilter.items.map(item => ({ ...item, label: f.translateItemLabels ? this.translate.instant(item.label) : item.label }))
              : [],
          };
        }
        return filter;
      });
    });
  }

  onFilterChange(filterId: string, event: any) {
    let filterToUpdate = this._filters.find(filter => filter.id === filterId);
    if (this.isMultiSelect(filterToUpdate)) {
      filterToUpdate = { ...filterToUpdate, selected: event.map(item => item.value) };
    } else if (this.isMultiSelectAddNew(filterToUpdate)) {
      filterToUpdate = { ...filterToUpdate, selected: event };
    } else if (this.isDateRange(filterToUpdate)) {
      filterToUpdate = { ...filterToUpdate, ...event };
    } else if (this.isTextBox(filterToUpdate)) {
      filterToUpdate = { ...filterToUpdate, selected: event.target.value };
    } else if (this.isSingleSelect(filterToUpdate)) {
      filterToUpdate = { ...filterToUpdate, selected: event && event.value };
    }

    this.selected = [...this.selected.filter(filter => filter.id !== filterId), filterToUpdate];
  }

  onSubmit() {
    this.selected.forEach(filter => {
      if (this.isMultiSelect(filter)) {
        const f = filter as MultiSelectFilter;
        f.selected = f.selected || [];
      }
    });
    this.change.emit(this.selected);
  }

  isMultiSelectAddNew(filter: Filter) {
    return filter.type === FilterType.MultiSelectAddNew;
  }

  isMultiSelect(filter: Filter) {
    return filter.type === FilterType.MultiSelect;
  }

  isSingleSelect(filter: Filter) {
    return filter.type === FilterType.SingleSelect;
  }

  isDateRange(filter: Filter) {
    return filter.type === FilterType.DateRange;
  }

  isDateRangeWithSelection(filter: Filter) {
    return filter.type === FilterType.DateRangeWithSelection;
  }

  isTextBox(filter: Filter) {
    return filter.type === FilterType.TextBox;
  }
}
