import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Terms } from '../models/terms.model';

export interface GetTermsResponse {
  status: number;
  success: boolean;
  data: Terms[];
}

@Injectable({
  providedIn: 'root',
})
export class TermsService {
  url = `${environment.apiUrl}v1/terms`;

  constructor(private http: HttpClient) {}

  getTerms(params?: {}) {
    return this.http.get<GetTermsResponse>(this.url, { params });
  }

  acceptTerms(term_id, term_language_id) {
    return this.http.post(`${this.url}/${term_id}/languages/${term_language_id}/accept`, {});
  }
}
