import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TrainingTemplateApiResponse } from './training-template.service';

@Injectable({
  providedIn: 'root',
})
export class FileAttachmentService {
  filesUrl = `${environment.apiUrl}v1/files`;
  attachUrl = `${environment.apiUrl}v1/attachfile`;

  constructor(private http: HttpClient) {
  }

  addFile(files: File[], organisationId: number, type: 'logo' | 'image' | 'document') {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files[]', file);
    }
    formData.append('organisation_id', organisationId.toString());
    formData.append('type', type);

    return this.http.post<any>(this.filesUrl, formData, { observe: 'events', reportProgress: true }).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round((100 * event.loaded) / event.total);
            return { status: 'progress', message: progress };
          case HttpEventType.Response:
            return event.body;
          default:
            return event;
        }
      }),
    );
  }

  attachFile(modelType: 'training_template' | 'location' | 'event', modelId: string, fileIds: number[], label: 'trainee' | 'trainer') {
    const formData = new FormData();
    for (const fileId of fileIds) {
      formData.append('files_id[]', fileId.toString());
    }
    formData.append('model_type', modelType);
    formData.append('model_id', modelId);
    formData.append('label', label);
    return this.http.post<TrainingTemplateApiResponse>(this.attachUrl, formData, {
      params: { with: 'trainee_attachments,trainer_attachments' },
    });
  }

  removeFile(id) {
    return this.http.delete(`${this.attachUrl}/${id}`);
  }

  removeFile2(id) {
    return this.http.delete(`${this.filesUrl}/${id}`);
  }
}
