import { NgModule } from '@angular/core';
import { LayoutRoutingModule } from './layout-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LayoutComponent } from './layout.component';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { AccordionModule } from 'primeng/accordion';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [LayoutComponent, SidebarComponent],
  imports: [SharedModule, LayoutRoutingModule, BreadcrumbModule, AccordionModule, NgbAccordionModule],
})
export class LayoutModule {
}
