<div class="row">
  <div class="col-5">
    <span class="text-muted">
      <ng-content select="div.desc"></ng-content>
    </span>
  </div>
  <div class="col-7">
    <span>
      <ng-content select="div.value"></ng-content>
    </span>
  </div>
</div>
<hr>
