<div class="modal-header border-0 pt-4 px-4">
  <h1 class="modal-title h4 w-100 text-center" id="modal-basic-title" translate>{{ title }}</h1>
  <button type="button" class="close" attr.aria-label="{{ cancelText | translate }}" (click)="activeModal.dismiss()">
    <fa-icon [icon]="['fal', 'times']" [fixedWidth]="true"></fa-icon>
  </button>
</div>
<div class="modal-body border-0">
  <div class="text-center">
    <div *ngIf="htmlText" [innerHTML]="htmlText"></div>
  </div>
  <div *ngIf="prompt" [innerHTML]="prompt" class="mt-4"></div>
  <textarea #answer class="w-100" rows="4">{{ answer.value }}</textarea>
</div>
<div class="modal-footer justify-content-center border-0 pb-4">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()" translate>{{ cancelText }}</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close(answer.value)" translate>{{ buttonText }}</button>
</div>
