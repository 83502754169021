import { Injectable } from '@angular/core';
// @ts-ignore
import countriesJSON from '../assets/countries/countries.json';
import { Country } from './models/country.model';
import { Role } from './models/role.model';
import { UserStatus } from './models/user-status.model';

@Injectable()
export class Globals {
  YES = 'yes';
  NO = 'no';
  countries: Country[] = countriesJSON.countries;
  finlandCountryCode = 69;
  language = {
    fi: 2,
    en: 1,
  };
  languages = [
    { id: 2, code: 'fi', name: 'Finnish', short_name: 'fi' },
    { id: 1, code: 'en', name: 'English', short_name: 'en' },
    { id: 3, code: 'se', name: 'Swedish', short_name: 'se' },
  ];
  roles: Role[] = [
    { id: 1, name: 'roles.trainer', slug: 'trainer' },
    { id: 2, name: 'roles.secretary', slug: 'secretary' },
    { id: 3, name: 'roles.training_center_admin', slug: 'training_center_admin' },
    { id: 4, name: 'roles.training_concept_admin', slug: 'training_concept_admin' },
  ];
  templateTypes = [
    { value: 'qualification', label: 'training-template.qualification' },
    { value: 'group', label: 'training-template.group' },
    { value: 'event', label: 'training-template.event' },
    { value: 'elearning', label: 'training-template.elearning' },
    { value: 'virtualreality', label: 'training-template.virtualreality' },
  ];
  nameRegexNotAllowed = `[01-9@]`;
  userStatuses: UserStatus[] = [{ name: 'users.statuses.active', value: 'active' }, { name: 'users.statuses.deleted', value: 'deleted' }];
  additionalParameters = {
    roles: 'roles',
    home_address: 'home_address',
    events: 'events',
    trainings: 'trainings',
    organisations: 'organisations',
    roles_details: 'roles_details',
    status: 'status',
  };
  TRAINING_ORGANISATION = 'organisation';
  CUSTOMER_COMPANY = 'customer';
  PARTICIPANT_ADDRESS = 'participant';
}
