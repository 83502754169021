import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private translate: TranslateService) {
  }

  changeLanguage(language) {
    this.translate.use(language);
    localStorage.setItem('language', language);
  }

  getUserSavedLanguage() {
    return localStorage.getItem('language');
  }
}
