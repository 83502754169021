import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TrainingTemplate, TrainingTemplateParams } from '../models/training-template.model';

export interface TrainingTemplateApiResponse {
  status: string;
  success: boolean;
  data: TrainingTemplate;
}

export interface TrainingTemplatesApiResponse {
  status: string;
  success: boolean;
  data: TrainingTemplate[];
  pagination: any;
}

@Injectable({
  providedIn: 'root',
})
export class TrainingTemplateService {
  url = `${environment.apiUrl}v1/trainingtemplates`;

  constructor(private http: HttpClient) {}

  getTrainingTemplate(id, params: {} = {}) {
    return this.http.get<TrainingTemplateApiResponse>(this.url + '/' + id, params);
  }

  getAll(params: {} = {}) {
    return this.http.get<TrainingTemplatesApiResponse>(this.url, { params: params });
  }

  addTrainingTemplate(payload: TrainingTemplateParams) {
    return this.http.post<TrainingTemplateApiResponse>(this.url, payload);
  }

  updateTrainingTemplate(id, params: {} = {}) {
    return this.http.put<TrainingTemplateApiResponse>(this.url + '/' + id, params);
  }

  getTemplateDuration(duration_in_minutes) {
    return `${Math.floor(duration_in_minutes / 60)}h  ${duration_in_minutes % 60}min`;
  }

  getCalculatedPrice(price_in_cents_without_vat) {
    return (price_in_cents_without_vat / 100).toFixed(2);
  }
}
