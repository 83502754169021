import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule, NgbNavModule,
  NgbPaginationModule,
  NgbTimepickerModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { TableModule } from 'primeng/table';
import { FiltersModule } from 'src/app/shared/filters';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { ConfirmationDialogModule } from './confirmation-dialog/confirmation-dialog.component';
import { ChangeOrganisationDialogModule } from './change-organisation-dialog/change-organisation-dialog.component';
import { DateRangeSelectionModule } from './date-range-selection/date-range-selection.component';
import { FileUploadModule } from './file-upload/file-upload.module';
import { PermissionsModule } from './permissions/permissions.module';
import { PromptDialogModule } from './prompt-dialog/prompt-dialog.component';
import { SearchFieldComponent } from './search-field/search-field.component';
import { TemplateDetailsModule } from './template-details/template-details.module';
import { UserDetailsModule } from './user-details/user-details.module';
import { FileListModule } from './file-list/file-list.component';
import { FileAddModalModule } from './file-add-modal/file-add-modal.component';
import { TermsDialogModule } from './terms-dialog/terms-dialog.component';
import { LayoutComponentModule } from './layout-component/layout-component.module';
import { PaginationComponent } from './pagination/pagination.component';
import { PageContainerModule } from './page-container/page-container.component';
import { PageTitleModule } from './page-title/page-title.component';
import { ChangeListsComponent } from './change-lists/change-lists.component';
import { NotesTimelineComponent } from './notes-timeline/notes-timeline.component';
import { PasswordValidationModule } from './password-validation/password-validation.component';
import { MergeConfirmationDialogModule } from '../users/users/merge-users/merge-confirmation-dialog/merge-confirmation-dialog.component';
import { PageTitleComponent } from './page-structure/page-title/page-title.component';
import { PageSubTitleComponent } from './page-structure/page-sub-title/page-sub-title.component';
import { PageColumnsComponent } from './page-structure/page-columns/page-columns.component';
import { PageFooterComponent } from './page-structure/page-footer/page-footer.component';
import { ParticipantStatusPipe } from './pipes/participant-status.pipe';
import { ExcelImportModule } from './excel-import-modal/excel-import-modal.component';
import { TrainingListingModalModule } from './training-listing-modal/training-listing-modal.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faBars,
  faHome,
  faGraduationCap,
  faUsers,
  faCogs,
  faMapMarkerAlt,
  faSitemap,
  faSearch,
  faLock,
  faAngleDown,
  faAngleUp,
  faCalendar,
  faCalendarPlus,
  faEdit,
  faCheck,
  faTimes,
  faCalendarAlt,
  faSignOutAlt,
  faCamera,
  faCog,
  faTimesCircle,
  faBuilding,
  faTable,
  faStar,
  faHomeLgAlt,
  faFile,
  faChalkboardTeacher,
  faSpinnerThird,
  faIdCard,
  faPrint,
  faQuestion,
  faCreditCard,
  faExclamation,
  faStream,
  faTrash,
  faSync,
  faDownload,
  faFileArchive,
  faRedo,
  faBook,
  faPlusSquare,
  faMinusSquare,
  faInfoCircle,
  faCheckCircle,
  faEye,
  faSyncAlt,
  faQuestionCircle,
  faExchange,
  faEllipsisV,
  faSpinner
} from '@fortawesome/pro-light-svg-icons';
import { FormControlDirective } from '../directives/form-control.directive';
import {NgSelectDirective} from "../directives/ng-select.directive";

@NgModule({
  declarations: [
    SearchFieldComponent,
    PaginationComponent,
    NotesTimelineComponent,
    ChangeListsComponent,
    PageTitleComponent,
    PageSubTitleComponent,
    PageColumnsComponent,
    PageFooterComponent,
    ParticipantStatusPipe,
    FormControlDirective,
    NgSelectDirective
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ToastrModule.forRoot(),
    NgbModalModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    TableModule,
    FontAwesomeModule,
    NgbTooltipModule,
    NgbTimepickerModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbPaginationModule,
    NgbNavModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'success', // set defaults here
    }),
  ],
  exports: [
    CommonModule,
    TranslateModule,
    ToastrModule,
    NgbModalModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    TableModule,
    SearchFieldComponent,
    PaginationComponent,
    NgbDropdownModule,
    FontAwesomeModule,
    NgbTooltipModule,
    NgbTimepickerModule,
    NgbNavModule,
    NgbDatepickerModule,
    DateRangeSelectionModule,
    ConfirmationDialogModule,
    ChangeOrganisationDialogModule,
    ExcelImportModule,
    TrainingListingModalModule,
    PromptDialogModule,
    FiltersModule,
    TemplateDetailsModule,
    UserDetailsModule,
    PermissionsModule,
    FileUploadModule,
    FileListModule,
    ConfirmationPopoverModule,
    FileAddModalModule,
    TermsDialogModule,
    LayoutComponentModule,
    PageContainerModule,
    PageTitleModule,
    ChangeListsComponent,
    NotesTimelineComponent,
    PasswordValidationModule,
    MergeConfirmationDialogModule,
    PageColumnsComponent,
    PageSubTitleComponent,
    PageTitleComponent,
    PageFooterComponent,
    ParticipantStatusPipe,
    FormControlDirective,
    NgSelectDirective
  ],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(
      faBars,
      faHome,
      faGraduationCap,
      faUsers,
      faCogs,
      faMapMarkerAlt,
      faSitemap,
      faSearch,
      faLock,
      faTrash,
      faAngleDown,
      faAngleUp,
      faCalendar,
      faCalendarPlus,
      faEdit,
      faCheck,
      faTimes,
      faCalendarAlt,
      faSignOutAlt,
      faCamera,
      faCog,
      faTimesCircle,
      faBuilding,
      faTable,
      faStar,
      faHomeLgAlt,
      faFile,
      faChalkboardTeacher,
      faSpinnerThird,
      faIdCard,
      faPrint,
      faQuestion,
      faCreditCard,
      faExclamation,
      faStream,
      faTrash,
      faSync,
      faDownload,
      faFileArchive,
      faRedo,
      faBook,
      faPlusSquare,
      faMinusSquare,
      faInfoCircle,
      faCheckCircle,
      faEye,
      faSyncAlt,
      faQuestionCircle,
      faExchange,
      faEllipsisV,
      faSpinner,
    );
  }
}
