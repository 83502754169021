import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ValidationMessagesService {
  customErrorMessages: { error: string, format: (label, error) => {} }[] = [
    {
      error: 'required',
      format: (label, error) => `${label} ${this.translateService.instant('errors.validation.field_is_required')}`,
    },
    {
      error: 'pattern',
      format: (label, error) => `${label} ${this.translateService.instant('errors.validation.pattern_is_invalid')}`,
    },
    {
      error: 'differentPassword',
      format: (label, error) => `${this.translateService.instant('errors.validation.password_do_not_match')}`,
    },
    {
      error: 'email',
      format: (label, error) => `${this.translateService.instant('errors.validation.email_is_invalid')}`,
    },
    {
      error: 'minlength',
      format: (label, error) => `${label} ${this.translateService.instant('errors.validation.minLength')}`,
    },
    {
      error: 'maxlength',
      format: (label, error) => `${label} ${this.translateService.instant('errors.validation.maxLength')}`,
    },
    {
      error: 'min',
      format: (label, error) => `${label} ${this.translateService.instant('errors.validation.min')}`,
    },
    {
      error: 'max',
      format: (label, error) => `${label} ${this.translateService.instant('errors.validation.max')}`,
    },
    {
      error: 'number',
      format: (label, error) => `${label} ${this.translateService.instant('errors.validation.number_is_invalid')}`,
    },
    {
      error: 'wrongNumber',
      format: (label, error) => `${this.translateService.instant('errors.validation.wrong_phonenumber')}`,
    },
    {
      error: 'duplicate_ssn',
      format: (label, error) => `${this.translateService.instant('errors.validation.duplicate_ssn')}`,
    },
    {
      error: 'duplicate_email',
      format: (label, error) => `${this.translateService.instant('errors.validation.duplicate_email')}`,
    },
    {
      error: 'ssn_in_wrong_format',
      format: (label, error) => `${this.translateService.instant('errors.validation.ssn_in_wrong_format')}`,
    },
    {
      error: 'ssn_with_wrong_checksum',
      format: (label, error) => `${this.translateService.instant('errors.validation.ssn_with_wrong_checksum')}`,
    },
    {
      error: 'birthdate_in_wrong_format',
      format: (label, error) => `${this.translateService.instant('errors.validation.birthdate_in_wrong_format')}`,
    },
    {
      error: 'birthdate_or_ssn_in_wrong_format',
      format: (label, error) => `${this.translateService.instant('errors.validation.birthdate_or_ssn_in_wrong_format')}`,
    },
    {
      error: 'name_not_allowed',
      format: (label, error) => `${this.translateService.instant('errors.validation.name_not_allowed')}`,
    },
    {
      error: 'unique',
      format: (label, error) => `${this.translateService.instant('errors.validation.duplicate')}`,
    },
  ];

  constructor(private translateService: TranslateService) {}

  errorMessages() {
    return this.customErrorMessages;
  }
}
