import { Component, ElementRef, Injectable, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, RouterModule } from '@angular/router';
import { BillingTrainingService } from '../../services/billing-training.service';

@Component({
  selector: 'app-training-listing-modal',
  templateUrl: './training-listing-modal.component.html',
  styleUrls: ['./training-listing-modal.component.scss'],
})
export class TrainingListingModalContentComponent implements OnInit {
  @Input() params: any;
  listingTitle: string;
  trainings: Array<any> = [];

  constructor(
    public activeModal: NgbActiveModal,
    private translate: TranslateService,
    private toastr: ToastrService,
    private billingTrainingService: BillingTrainingService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.listingTitle =
      this.params.organisationName +
      ' - ' +
      this.params.templateName +
      (this.params.invoice_reference ? ' - ' + this.params.invoice_reference : '') +
      (this.params.reduced_price ? ' - ' + this.translate.instant('training-event.reduced_price') : '');
    this.billingTrainingService.getAll({ params: this.params }).subscribe((res: any) => {
      this.trainings = res.data;
    });
  }

  openTraining(id) {
    this.activeModal.close();
    this.router.navigate(['/trainings/' + id]);
  }

  openEvent(id) {
    this.activeModal.close();
    this.router.navigate(['/events/' + id]);
  }
}

@Injectable({
  providedIn: 'root',
})
export class TrainingListingModalComponent {
  constructor(private modalService: NgbModal) {}

  show(options) {
    const content = this.modalService.open(TrainingListingModalContentComponent, { size: 'xl' });
    content.componentInstance.params = options.params;

    return content.result;
  }
}

@NgModule({
  declarations: [TrainingListingModalContentComponent],
  imports: [TranslateModule, CommonModule, FontAwesomeModule, FormsModule, RouterModule],
})
export class TrainingListingModalModule {}
