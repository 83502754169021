<div *ngFor="let file of fileArray; let i = index" class="row mb-2">
  <div class="col-12 text-primary">
    <div class="border border-primary rounded p-2">
      <div class="row mx-0">
        <div class="col-2">
          <fa-icon class="fa-lg" [icon]="['fal', 'file']" [fixedWidth]="true"></fa-icon>
        </div>
        <div class="col-8 text-truncate">
          <span>{{file.name}}</span>
        </div>
        <div class="col-2">
          <span mwlConfirmationPopover
                [popoverTitle]="'files.confirmation' | translate"
                [popoverMessage]="'files.confirmation_message' | translate"
                [cancelText]="'common.no' | translate"
                [confirmText]="'common.yes' | translate"
                placement="top"
                (confirm)="removeFile(file, i)"
          ><fa-icon
                    [icon]="['fal', 'times']"
                    [fixedWidth]="true"
          ></fa-icon></span>
        </div>
      </div>
    </div>
  </div>
</div>
