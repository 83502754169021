import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LangChangeEvent, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from 'xng-breadcrumb';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Globals } from './globals';
import { AuthInterceptor, CodeInterceptor, OutboundInterceptor } from './interceptors/http.interceptor';
import { LayoutModule } from './layout/layout.module';
import { Helpers } from './services/helpers';
import { LanguageService } from './services/language.service';
import { NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n } from './services/ngb-datepicker-i18n.service';
import { CustomDatepickerFormatter } from './services/ngb-datepicker-formatter';
import { Meta, Title } from '@angular/platform-browser';
import { Observable, from } from 'rxjs';
import * as Sentry from "@sentry/angular-ivy";

export class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/lang/${lang}.json`));
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    LayoutModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader,
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OutboundInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CodeInterceptor,
      multi: true,
    },
    Globals,
    Helpers,
    Title,
    Meta,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: CustomDatepickerFormatter },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translate: TranslateService, private languageService: LanguageService, private breadcrumbService: BreadcrumbService) {
    this.translate.setDefaultLang('en');
    this.translate.use(this.languageService.getUserSavedLanguage() || translate.getDefaultLang());

    /**
     * Breadcrumb translation assumes that all keys are under breadcrumb object in translation structure
     * Example usage in route data: breadcrumb: {alias: 'breadcrumb.events'}
     *
     * Best solution would have been to customize breadcrumb template to use translate directive but that is not supported currently
     */
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const keys = event.translations && event.translations.breadcrumb ? Object.keys(event.translations.breadcrumb) : [];
      keys.forEach(key => {
        const alias = `breadcrumb.${key}`;
        this.breadcrumbService.set('@' + alias, this.translate.instant(alias));
      });
    });
  }
}
