import { Component, ElementRef, EventEmitter, Injectable, Input, NgModule, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ExcelImportService } from '../../services/excel-import.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-excel-import-modal',
  templateUrl: './excel-import-modal.component.html',
  styleUrls: ['./excel-import-modal.component.scss'],
})
export class ExcelImportModalContentComponent implements OnInit {
  @ViewChild('excelImportButton', { static: false }) excelImportButton: ElementRef;
  @Input() columns: Array<string>;

  excelData: Array<any> = [];
  mappedData: Array<any> = [];
  columnOrder: Array<string> = []; // select value is always string
  showPreview = false;

  constructor(
    public activeModal: NgbActiveModal,
    private translate: TranslateService,
    private toastr: ToastrService,
    private excelImportService: ExcelImportService,
  ) {}

  ngOnInit() {
    this.columns.forEach((col, index) => {
      this.columnOrder[index] = index.toString();
    });
  }

  excelImport(files: any) {
    if (files.files && files.files.length > 0) {
      if (this.excelImportService.validateExcel(files)) {
        this.excelImportService.handleExcel(files).then((value: Array<any>) => {
          this.excelData = value;
          this.mapData();
          this.showPreview = true;
        });
      } else {
        this.toastr.error(this.translate.instant('training-event.show.file_wrong_format'));
      }
    }
  }

  mapData() {
    this.mappedData = [];
    this.excelData.forEach(row => {
      const cols = {};
      this.columns.forEach((col, colIndex) => {
        const searchIndex = this.columnOrder.indexOf(colIndex.toString());
        if (searchIndex > -1 && row[searchIndex]) {
          cols[col] = this.formatCells(col, row[searchIndex]);
        } else {
          cols[col] = '';
        }
      });
      this.mappedData.push(cols);
    });
  }

  formatCells(name, value) {
    switch (name) {
      case 'ssn':
        value = value.toString().replace(/[^a-zA-Z0-9\-]/g, '');
        break;
    }
    return value.toString().trim();
  }

  importParticipants() {
    this.activeModal.close(this.mappedData);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ExcelImportModalComponent {
  constructor(private modalService: NgbModal) {}

  show(options) {
    const content = this.modalService.open(ExcelImportModalContentComponent, { size: 'xl' });
    content.componentInstance.columns = options.columns;

    return content.result;
  }
}

@NgModule({
  declarations: [ExcelImportModalContentComponent],
  imports: [TranslateModule, CommonModule, FontAwesomeModule, FormsModule],
})
export class ExcelImportModule {}
