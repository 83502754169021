import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class CodeInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        code: 'ca',
      },
    });
    const token = localStorage.getItem('ng2-ui-auth_accessToken');
    if (token) (
      request = request.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + token,
        },
      })
    );
    return next.handle(request);
  }
}

@Injectable()
export class OutboundInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modified = req.clone({ setHeaders: { 'Accept-Language': localStorage.getItem('language') || 'en' } });
    return next.handle(modified);
  }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: any) => {
        if (error.error?.error?.code === 'unauthenticated') {
          this.router.navigate(['/']).then(() => localStorage.clear());
        }
        return throwError(error);
      }),
    );
  }
}
