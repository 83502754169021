import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationErrorService {

  constructor(private translateService: TranslateService) {
  }

  getCustomErrorMessages(): { error: string, format: (label?: any, error?: any) => string }[]  {
    return [
      {
        error: 'required',
        format: () => `${this.translateService.instant('validation_errors.validation_error_required')}!`
      }, {
        error: 'email',
        format: () => `${this.translateService.instant('validation_errors.validation_error_email')}!`
      }, {
        error: 'pattern',
        format: () => `${this.translateService.instant('validation_errors.validation_error_pattern')}!`
      }, {
        error: 'min',
        format: () => `${this.translateService.instant('validation_errors.validation_error_min')}!`
      }, {
        error: 'max',
        format: () => `${this.translateService.instant('validation_errors.validation_error_max')}!`
      }, {
        error: 'number',
        format: () => `${this.translateService.instant('validation_errors.validation_error_number')}!`
      }, {
        error: 'birthdate',
        format: () => `${this.translateService.instant('validation_errors.validation_error_birthdate')}!`
      }, {
        error: 'phone',
        format: () => `${this.translateService.instant('validation_errors.validation_error_phone')}!`
      }, {
        error: 'minlength',
        format: (label, error) => `${this.translateService.instant('validation_errors.validation_error_minlength', {requiredLength: error?.requiredLength})}!`
      }, {
        error: 'maxlength',
        format: (label, error) => `${this.translateService.instant('validation_errors.validation_error_maxlength', {requiredLength: error?.requiredLength})}!`
      },
      {
        error: 'wrongNumber',
        format: () => `${this.translateService.instant('validation_errors.validation_error_phone')}!`
      },
      {
        error: 'birthdate_or_ssn_in_wrong_format',
        format: () => this.translateService.instant('validation_errors.birthdate_or_ssn_in_wrong_format')
      }
    ];
  }
}
