import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersComponent } from 'src/app/shared/filters/filters.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule } from 'primeng/accordion';
import { FormsModule } from '@angular/forms';
import { DateRangeSelectionModule } from 'src/app/shared/date-range-selection/date-range-selection.component';

@NgModule({
  declarations: [FiltersComponent],
  imports: [TranslateModule, CommonModule, NgSelectModule, AccordionModule, FormsModule, DateRangeSelectionModule],
  exports: [FiltersComponent],
})
export class FiltersModule {}
