import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
})
export class SearchFieldComponent implements OnInit {
  @Input() searchTerm: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Output() search = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
  empty() {
    this.searchTerm = '';
    this.search.emit(this.searchTerm);
  }
}
